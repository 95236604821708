import { Briefcase } from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { trackAnalytics } from 'src/pages/helpers';
import { setShowScheduleMeeting } from 'src/pages/onboarding/onboardingSlice';
import { setShowRequestLegalReviewAside } from 'src/pages/onboarding/onboardingSlice';

const TalkToLawyerButton = ({ userMetadata, email }) => {
    const dispatch = useDispatch()
    const handleClick = () => {
        trackAnalytics("ChatPage_TalkToLawyerButton_Clicked", { "user_email": email },
            userMetadata
        )
        // dispatch(setShowFindBestLawyer(true))
        dispatch(setShowRequestLegalReviewAside(true));
    };
    const handleClickSmallScreen = () => {
        dispatch(setShowScheduleMeeting({ "show": true, "lawyer_details": {} }))
    };

    return (
        <>
            <button className="bg-[#18475A] border w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] hidden md:flex flex-row"
                onClick={() => handleClick()}>
                <span className="text-white my-auto text-[14px] leading-[19.6px]">Talk To a Lawyer</span>
                <span className="my-auto"><Briefcase size={14} className="text-white my-auto" /></span>
            </button>
            <button className="bg-[#18475A] border w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row md:hidden"
                onClick={() => handleClickSmallScreen()}>
                <span className="text-white my-auto text-[14px] leading-[19.6px]">Talk To a Lawyer</span>
                <span className="my-auto"><Briefcase size={14} className="text-white my-auto" /></span>
            </button>
        </>
    );
};

export default TalkToLawyerButton;