import React, { useEffect, useState } from 'react';
import Modal from "antd";
import { Briefcase, CalendarDots, CreditCard } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPageLegalReviewStatus, setShowFindBestLawyer, setShowLegalReviewCompletedModal, setShowLegalReviewRequestStatus } from '../onboarding/onboardingSlice';
import { openPopup } from 'src/components/DocumentViewer/helpers';
import { PLANS, ReviewStatus } from 'src/components/Constants';
import { useUser } from '@clerk/clerk-react';
const LegalReviewMessagePrompt = (props) => {
    const { user } = useUser()
    const dispatch = useDispatch();
    const { threadData } = props;
    const [pageData, setPageData] = useState({})
    const [legalReviewRequest, setLegalReviewRequest] = useState({})
    const [showCta, setShowCta] = useState(false)

    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);


    const completePayment = () => {
        openPopup(dispatch, "legal_review");
    }

    const scheduleCall = () => {
        dispatch(setShowFindBestLawyer(true))
    }

    const showScheduledcall = () => {
        dispatch(setShowLegalReviewRequestStatus(true))
    }

    const showLaywerDetails = () => {
        dispatch(setShowLegalReviewRequestStatus(true))
    }

    const viewReviewDetails = () => {
        dispatch(setShowLegalReviewCompletedModal(true))
    }

    useEffect(() => {
        if (lawyerHubData && userMetadata && threadData) {
            const legalReview = lawyerHubData.find((item) => item.thread_id === threadData.id);
            setLegalReviewRequest(legalReview);
            getPageData(legalReview);
            dispatch(setCurrentPageLegalReviewStatus(legalReview));
        }
    }, [lawyerHubData, userMetadata, threadData]);

    const getPageData = (legalReviewRequest) => {
        // TODO : TO Review with Aarshay
        switch (legalReviewRequest?.status) {
            case ReviewStatus.in_progress:
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-accurate rounded-full" id="content"><Briefcase size={24} className='text-[#353535]' /></div>,
                    "iconColor": "#353535",
                    "iconBgColor": "#fbf6e8",
                    "textLine1": "Lawyer Review Requested",
                    "textLine2": "We will connect you with a top-rated Lawyer.",
                });
                setShowCta(false)
                break;
            case ReviewStatus.completed:
                var date = new Date(legalReviewRequest.lawyer_submission_metadata?.lr_submitted_by_lawyer_at + "Z");

                var message = ` on ${date.toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                })}`;
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><Briefcase size={24} className='text-[#DCA218]' /></div>,
                    "iconColor": "#DCA218",
                    "iconBgColor": "#fbf6e8",
                    "textLine1": "Review Completed",
                    "textLine2": message,
                    "cta": "View Lawyer Review",
                    "cta_action": viewReviewDetails
                });
                setShowCta(userMetadata?.subscription_type !== PLANS.PERSONAL_FREE)
                break;
            case ReviewStatus.pending_lawyer_matching:
                var textLine1, textLine2, cta, cta_action;
                var threadReview = lawyerHubData?.find((item) => item.thread_id === threadData?.id)
                const subscriptionType = userMetadata?.subscription_type;
                const meetingTime = subscriptionType === PLANS.PERSONAL_FREE
                    ? threadReview?.lr_lawyer_matching_meeting_time
                    : userMetadata?.lawyer_matching_meeting_at;
                if (userMetadata?.subscription_type !== PLANS.PERSONAL_FREE && meetingTime) {
                    // console.log("Time" + threadReview?.lr_lawyer_matching_meeting_time)
                    // call is scheduled
                    let date = new Date(userMetadata?.lawyer_matching_meeting_at + "Z");
                    // let userTimezoneOffset = date.getTimezoneOffset() * 60000;
                    // date = new Date(date.getTime() + userTimezoneOffset);

                    textLine1 = "Lawyer Review Requested"
                    textLine2 = `Scheduled a call with legal support team on ${date.toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}`
                    cta = "View Details"
                    cta_action = showScheduledcall
                    setShowCta(userMetadata?.subscription_type !== PLANS.PERSONAL_FREE)
                }
                else if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE && meetingTime) {
                    // console.log("Time" + meetingTime)
                    let date = new Date(threadReview?.lr_lawyer_matching_meeting_time + "Z");
                    // let userTimezoneOffset = date.getTimezoneOffset() * 60000;
                    // date = new Date(date.getTime() + userTimezoneOffset);

                    textLine1 = "Meeting scheduled with legal support team"
                    textLine2 = `Scheduled a call with legal support team on ${date.toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}`
                    cta = "View Details"
                    cta_action = showScheduledcall
                }
                else {
                    // call is not yet scheudled
                    textLine1 = "Schedule a call to find the lawyer that matches your needs"
                    textLine2 = "Our customer team will help you find the best lawyer for your specific needs"
                    cta = "Schedule Call"
                    cta_action = scheduleCall
                    setShowCta(true)
                }
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><CalendarDots size={24} className='text-[#DCA218]' /></div>,
                    "iconColor": "#DCA218",
                    "iconBgColor": "#fbf6e8",
                    "textLine1": textLine1,
                    "textLine2": textLine2,
                    "cta": cta,
                    "cta_action": cta_action
                });
                break;
            default:
                setPageData({})
        }
    }

    useEffect(() => {
        if (lawyerHubData) {
            // debugger;
            var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
            setLegalReviewRequest(legalReview);
            getPageData(legalReview)
            dispatch(setCurrentPageLegalReviewStatus(legalReview))
        }
    }, [lawyerHubData]) // eslint-disable-line


    return (
        <>
            <div className="flex flex-row justify-between bg-white gap-[12px] border p-[24px] rounded-[12px] my-[12px]">
                <div className={`flex flex-row gap-[12px] ${showCta ? "w-[528px]" : "w-full"} my-auto`} id="left-side">
                    {pageData?.icon}
                    <div className="flex flex-col gap-[4px] my-auto" id="icon">
                        <p className="text-[#333333] text-[14px] leading-[21px] font-[700]">{pageData?.textLine1}</p>
                        <p className="text-[#686868] text-[14px] leading-[19.6px] font-[500] ">{pageData?.textLine2}</p>
                    </div>
                </div>
                {showCta &&
                    <div id="right-side" className="my-auto">
                        <p className="my-auto text-[#18475A] font-[700] text-[14px] leading-[19.6px] underline cursor-pointer" onClick={pageData?.cta_action}>{pageData?.cta}</p>
                    </div>
                }
            </div>
        </>
    );
}

export default LegalReviewMessagePrompt;