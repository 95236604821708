import { setUserIdentified } from "./onboarding/onboardingSlice";
import { Sparkle, FolderLock, Briefcase, Bank, Building, Certificate, Receipt, MagnifyingGlass, HandDeposit, Note, Users, Medal, Pen, GitDiff, Cpu, Signature, LockKey, CalendarDots, Scroll, Copyright, FileMagnifyingGlass, HouseLine, Key, Handshake, ChartLineUp, Clipboard, Scales, Gavel, Trademark, Lock, Envelope } from "@phosphor-icons/react";

export function extractErrors(response) {
  const errors = {
    fieldErrors: {},
    genericErrors: [],
  };
  if (response && (response.status === 404 || response?.response?.status === 404)) {
    errors.genericErrors.push("The requested resource was not found.");
  }
  if (response && (response.status >= 500 || response?.response?.status >= 500)) {
    errors.genericErrors.push("Something went wrong. Please try again later.");
  } else if (
    response &&
    response.name &&
    response.name === "AxiosError" &&
    (response.status !== 422 || response?.response?.status !== 422)
  ) {
    errors.genericErrors.push(response.message);
  }
  if (response && response?.response?.status === 422) {
    const detail = response.response.data.detail;
    if (detail && detail.length > 0) {
      detail.forEach((error) => {
        if (error.loc && error.loc.length > 0) {
          if (!errors.fieldErrors[error.loc[1]]) {
            errors.fieldErrors[error.loc[1]] = [];
          }
          errors.fieldErrors[error.loc[1]].push(error.msg);
        } else {
          errors.genericErrors.push(error.msg);
        }
      });
    }
  } else {
    if (response && response.data && response.data.errors) {
      response.data.errors.forEach((error) => {
        if (error.field) {
          if (!errors.fieldErrors[error.field]) {
            errors.fieldErrors[error.field] = [];
          }
          errors.fieldErrors[error.field].push(error.message);
        } else {
          errors.genericErrors.push(error.message);
        }
      });
    }
  }

  return errors;
}


export const trackAnalytics = (event, data, metadata) => {
  data = data || {};
  data["user_id"] = metadata?.user_id
  window.analytics.track(event, data);
}


export const clearTokens = () => {
  try {
    localStorage.removeItem("accessToken2");
    localStorage.removeItem("refreshToken2");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    localStorage.removeItem("client_details");
    localStorage.removeItem("lawyer_info");
    localStorage.removeItem("showForm");
    localStorage.removeItem("accessToken2");
  } catch (e) {
    console.log(e)
  }
  // window.analytics.reset() // this is to clear segment anonymous id
}


export const identifyAnalytics = (email, userMetadata, dispatch) => {
  var data = {
    email: email,
    first_name: userMetadata?.first_name,
    last_name: userMetadata?.last_name,
    organization_name: userMetadata?.entity_info?.name || "",
    state_incorporation: userMetadata?.entity_info?.state_incorporation || "",
    state_business: userMetadata?.entity_info?.primary_business_state || "",
    state_residence: userMetadata?.individual_info?.state_of_residence || "",
    account_type: userMetadata?.entity_info ? "business" : "personal",
    payment_frequency: userMetadata?.payment_frequency || "",
    assigned_lawyer_name: userMetadata?.lawyer_info?.name || "",
    assigned_lawyer_email: userMetadata?.lawyer_info?.email || "",
    subscription_type: userMetadata?.subscription_type || "",
  }
  var id = userMetadata?.user_id
  if (id) {
    window.analytics.identify(id, data);
    dispatch(setUserIdentified(true))
  }
}


export const getDraftIcon = (icon, size) => {
  switch (icon) {
    case 'briefcase':
    case "Briefcase":
      return <Briefcase size={size} />;
    case 'bank':
    case "Bank":
      return <Bank size={size} />;
    case 'building':
    case "Building":
      return <Building size={size} />;
    case 'certificate':
    case "Certificate":
      return <Certificate size={size} />;
    case 'receipt':
    case "Receipt":
      return <Receipt size={size} />;
    case 'folder':
    case "Folder":
      return <FolderLock size={size} />;
    case "Medal":
      return <Medal size={size} />;
    case "FolderLock":
      return <FolderLock size={size} />;
    case "HandDeposit":
      return <HandDeposit size={size} />;
    case "Envelope":
      return <Envelope size={size} />;
    case "Pen":
      return <Pen size={size} />;
    case "GitDiff":
      return <GitDiff size={size} />;
    case "Cpu":
      return <Cpu size={size} />;
    case "Note":
      return <Note size={size} />;
    case "Users":
      return <Users size={size} />;
    case "Signature":
      return <Signature size={size} />;
    case "LockKey":
      return <LockKey size={size} />;
    case "CalendarDots":
      return <CalendarDots size={size} />;
    case "scroll":
    case "Scroll":
      return <Scroll size={size} />

    case "ChartLineUp":
      return <ChartLineUp size={size} />;
    case "Handshake":
      return <Handshake size={size} />;
    case "Key":
      return <Key size={size} />;
    case "Trademark":
      return <Trademark size={size} />;
    case "Clipboard":
      return <Clipboard size={size} />;
    case "HouseLine":
      return <HouseLine size={size} />;
    case "FileMagnifyingGlass":
      return <FileMagnifyingGlass size={size} />;
    case "Scales":
      return <Scales size={size} />;
    case "Gavel":
      return <Gavel size={size} />
    case "Copyright":
      return <Copyright size={size} />
    default:
      return <Lock size={size} />;
  }
}