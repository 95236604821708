import { ChatsCircle, Lightning, PenNib } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowUpgradePlanNudge } from 'src/pages/onboarding/onboardingSlice';
import { resetPaymentStatus, setCheckoutUrl } from '../chatSlice';
import { trackAnalytics } from 'src/pages/helpers';
import { generateSharedId, setThreadSelectedToShare } from '../threadsSlice';
import ShareThreadModal from 'src/components/chat/Modals/ShareThreadModal';
import { ReviewStatus, PLANS } from 'src/components/Constants';
import ShareButton from './ShareButton';
import RequestReviewButton from './RequestReviewButton';
import UpgradeToPremiumButton from './UpgradeToPremiumButton';
import TasksLeftText from './TasksLeftText';
import TalkToLawyerButton from './TalkToLawyerButton';
import TalkToDedicatedLawyerButton from './TalkToDedicatedLawyerButton';
import ReviewPendingButton from './ReviewPendingButton';
import ReviewCompletedButton from './ReviewCompletedButton';
import CallScheduled from './CallScheduled';

const TopNav = ({ page }) => {
    const dispatch = useDispatch();
    const [text, setText] = React.useState('');
    const [duration, setDuration] = React.useState('')
    const [actionOnThread, setActionOnThread] = React.useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
    const [updateActionLoading, setUpdateActionLoading] = React.useState(false);
    const [icon, setIcon] = React.useState(<span><ChatsCircle size={16} className="text-[#C61DCA]" /></span>);

    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);

    const threads = useSelector((state) => state.threads.threads);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const threadData = useSelector(state => state.messageSlice.threadData);
    const email = useSelector(state => state.onboarding.email);
    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
    const threadTitleTop = useSelector((state) => state.messageSlice.threadTitleTop);

    useEffect(() => {
        if (page === "chat" && Object.keys(threads).length > 0) {
            var keyName = "";
            for (var key in threads) {
                var index = threads[key].find(thread => thread.id === threadData.id);
                if (index) {
                    keyName = key;
                    break;
                }
            }
            if (keyName) {
                setDuration(key)
            }
        }
    }, [page, threads]) // eslint-disable-line

    useEffect(() => {
        if (window.location.pathname.search("draft") > -1) {
            setIcon(<span><PenNib size={16} className="text-[#13866F]" /></span>)
        }
        else {
            setIcon(<span><ChatsCircle size={16} className="text-[#C61DCA]" /></span>)
        }
        if (page !== "ask_home") {
            if (threadData?.title) {
                setText(threadData.title);
            }
            else if (threadData?.isSharedThread || threadData.is_shared) {
                setText(threadData?.title);
            }
            else {
                if ((threadTitleTop) && (threadTitleTop?.title)) {
                    setText(threadTitleTop.title);
                }
                else {
                    if (threadData?.metadata?.created_at) {
                        setText(new Date(threadData?.metadata?.created_at).toLocaleDateString('en-US', options))
                    }
                    else {
                        setText(formattedDate)
                    }
                }
            }
        }
        else {
            setText(formattedDate)
        }
    }, [threadData]) // eslint-disable-line

    const openUpgradeModal = () => {
        trackAnalytics("subscription popup triggered", { "position": "topnav", "user_email": email }, userMetadata)
        trackAnalytics("dashboard_home_upgrade", { "user_email": email }, userMetadata)
        dispatch(setCheckoutUrl(""))
        dispatch(resetPaymentStatus())
        dispatch(setShowUpgradePlanNudge(true))
    }

    const shareThread = () => {
        setActionOnThread('Share');
        trackAnalytics("thread shared: topnav",
            { "thread_id": threadData.id, "user_email": email },
            userMetadata)
        dispatch(setThreadSelectedToShare(threadData.id));
        dispatch(generateSharedId({ thread: threadData, "duration": duration }))
    }
    const showBanner = () => {

        switch (userMetadata?.subscription_type) {
            case "":
            case null:
            case undefined:
                return <></>
            case PLANS.PERSONAL_FREE:

                return <div className="flex flex-row gap-[16px]">
                    <TasksLeftText userMetadata={userMetadata} />
                    <TalkToLawyerButton userMetadata={userMetadata} email={email} />
                </div>;
            case PLANS.FREE_TIER:
                return <div className="flex flex-row gap-[16px]">
                    <TasksLeftText userMetadata={userMetadata} />
                    <UpgradeToPremiumButton openUpgradeModal={openUpgradeModal} accountType={userMetadata?.subscription_type} />
                </div>;
            case PLANS.SUBSCRIBER_BUSINESS:
            case PLANS.BUSINESS_TRIAL:
                var renew_date, daysLeft, isCallScheduled;
                if (userMetadata?.subscription_renewal_date) {
                    renew_date = new Date(userMetadata?.subscription_renewal_date);
                    daysLeft = Math.floor((renew_date - currentDate) / (1000 * 60 * 60 * 24));
                }
                else {
                    daysLeft = 0;
                }
                isCallScheduled = userMetadata?.lawyer_matching_meeting_at;

                return <div className="flex flex-row gap-[16px]">
                    <TasksLeftText userMetadata={userMetadata} />
                    {((userMetadata?.subscription_type === PLANS.SUBSCRIBER_BUSINESS && userMetadata?.threads_remaining <= 3) || (userMetadata?.subscription_type === PLANS.BUSINESS_TRIAL)) &&
                        <UpgradeToPremiumButton openUpgradeModal={openUpgradeModal} daysLeft={daysLeft} accountType={userMetadata?.subscription_type} />
                    }
                    {userMetadata?.lawyer_info ?
                        <TalkToDedicatedLawyerButton userMetadata={userMetadata} />
                        :
                        <>{isCallScheduled ? <><CallScheduled /></> :
                            <TalkToLawyerButton userMetadata={userMetadata} email={email} />
                        }</>
                    }
                </div >
            case PLANS.BUSINESS_TRIAL_ENDED:
                var renew_date, daysLeft, isCallScheduled;
                if (userMetadata?.subscription_renewal_date) {
                    renew_date = new Date(userMetadata?.subscription_renewal_date);
                    daysLeft = Math.floor((renew_date - currentDate) / (1000 * 60 * 60 * 24));
                }
                else {
                    daysLeft = 0;
                }
                isCallScheduled = userMetadata?.lawyer_matching_meeting_at;

                return <div className="flex flex-row gap-[16px]">
                    <TasksLeftText userMetadata={userMetadata} />
                    <UpgradeToPremiumButton openUpgradeModal={openUpgradeModal} daysLeft={daysLeft} accountType={userMetadata?.subscription_type} />
                    {userMetadata?.lawyer_info ?
                        <TalkToDedicatedLawyerButton userMetadata={userMetadata} />
                        :
                        <>{isCallScheduled ? <><CallScheduled /></> :
                            <TalkToLawyerButton userMetadata={userMetadata} email={email} />
                        }</>
                    }
                </div >
            case PLANS.SUBSCRIBER_ENTERPRISE:
                return <div className="flex flex-row gap-[16px]">
                    <div className="flex flex-row gap-[8px] my-auto">
                        <span className="my-auto text-[12px] leading-[18px]">You have unlimited tasks</span>
                        <span className="my-auto"><Lightning size={16} className="my-auto text-[#18475A]" weight="fill" /> </span>
                    </div>
                    {userMetadata?.lawyer_info ?
                        <TalkToDedicatedLawyerButton userMetadata={userMetadata} />
                        :
                        <>{isCallScheduled ? <><CallScheduled /></> :
                            <TalkToLawyerButton userMetadata={userMetadata} email={email} />
                        }</>
                    }
                </div>;

            default:
                return <></>
        }
        // case payment_status = "starter/business/enterprise"
    }

    if (page === "home") {
        return (
            <div className="flex flex-row">
                <div className="flex justify-end w-full py-[16px] mx-auto px-[48px] gap-[16px]">
                    {showBanner()}
                </div>
            </div>
        );
    } else if (page === "ask_home" || page === "chat") {
        var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);

        var meetingScheduled = false;
        if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE && legalReview?.lr_lawyer_matching_meeting_time) {
            meetingScheduled = true;
        }
        else if (userMetadata?.lawyer_matching_meeting_at) {
            meetingScheduled = true;
        }
        return (
            <div className="flex flex-row ">
                <div className="flex justify-between w-full py-[12px] border-b border-[#E4E4E4] px-[24px] bg-[#FCFCFA]">
                    <h1 className='flex flex-row gap-[8px] my-auto py-auto'>
                        {icon}
                        <span className='text-[14px] leading-[21px] font-[500] my-auto'>{text}</span>
                    </h1>
                    {page === "chat" &&
                        <div className='flex justify-between gap-[16px]'>
                            {!legalReview ?
                                <>
                                    {!threadData?.isSharedThread &&
                                        <RequestReviewButton threadData={threadData} />
                                    }
                                </>
                                :
                                <>
                                    {legalReview.status !== ReviewStatus.completed ?
                                        <ReviewPendingButton userMetadata={userMetadata} legalReview={legalReview} meetingScheduled={meetingScheduled} />
                                        :
                                        <ReviewCompletedButton />
                                    }
                                </>

                            }

                            <ShareButton shareThread={shareThread} />
                        </div>
                    }
                    <ShareThreadModal
                        open={actionOnThread === 'Share'}
                        setActionOnThread={setActionOnThread}
                        actionOnThread="share"
                        threadInfo={{ "thread": threadData }}
                        loading={updateActionLoading}
                        setLoading={setUpdateActionLoading}
                    />

                </div>
            </div >
        );

    }
    else {
        return (
            <div className="flex flex-row w-full h-full py-auto">
            </div>
        )
    }

}

export default TopNav;