import React from 'react';
import { X } from '@phosphor-icons/react';

const SupportPopup = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white w-[500px] h-[300px] flex flex-col justify-center items-center rounded-lg shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        <h2 className="text-xl font-semibold mb-4">Looking for help?</h2>
        <p className="text-base text-gray-700 text-center">
          Drop an email to{' '}
          <a href="mailto:support@inhouse.app" className="font-medium text-blue-600" target="_blank" rel="noreferrer">support@inhouse.app</a>
        </p>

      </div>
    </div>
  );
};

export default SupportPopup;
