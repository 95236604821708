import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWebSocketContext } from 'src/WebsocketProvider';
import { Info, ArrowsClockwise } from '@phosphor-icons/react';
import AskAvatar from "../../images/AskAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";

const RegeneratePrompt = (props) => {
    const { threadData, chatInfo } = props;

    const { sendMessage, createMessage } = useWebSocketContext();
    const [avatar, setAvatar] = useState(null);

    const regenerate = () => {
        var threadType = threadData?.thread_type;
        var message = createMessage(threadType, "regenerate-response", {
            thread_id: threadData.id,
            last_message_id: threadData?.lastMessageId
        })
        sendMessage(message, true)

    }
    useEffect(() => {
        if (threadData?.thread_type === "ask") {
            setAvatar(AskAvatar)
        } else {
            setAvatar(DraftAvatar)
        }
    }, [threadData, chatInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    if (chatInfo !== threadData?.messages?.[threadData.messages.length - 1]) {
        return null;
    }


    return (
        <div className='mx-auto w-[736px]'>
            <div className='flex flex-col gap-[24px] py-[24px]'>
                <div className='w-full h-[82px] border-[1px] border-red-200 bg-red-50 flex items-start p-[16px] gap-[10px] rounded-lg'>
                    <Info size={36} className='text-red-700' />
                    <p className='font-sans font-medium text-[16px] text-[#353535]'>We encountered an issue while processing your request. Please regenerate the request to try again. If it persists, please reach out to the customer support <span className='text-[#248370] underline'>support@inhouse.so</span> </p>
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <button className='flex items-center justify-center gap-[8px] px-[24px] py-[12px] bg-[#18475A] text-white rounded-lg w-[163px]' onClick={() => regenerate()}>Regenerate <ArrowsClockwise size={16} /></button>
            </div>
        </div>
    );
};

export default RegeneratePrompt;