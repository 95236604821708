import React, { useState } from "react";

const ToggleSwitch = ({ isOn, setIsOn }) => {

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <div
      onClick={toggleSwitch}
      className={`flex items-center w-11 h-4 cursor-pointer rounded-full ${isOn ? "bg-[#18475A]" : "bg-gray-400"
        } transition-colors duration-300`}
    >
      <div
        className={`w-6 h-6 border bg-white rounded-full shadow-md transform transition-transform duration-300 ${isOn ? "translate-x-6" : "translate-x-0"
          }`}
      ></div>
    </div>
  );
};

export default ToggleSwitch;
