import { ArrowRight, Check, Info, VideoCamera } from '@phosphor-icons/react';
import React from 'react';
import { setShowRequestLegalReviewAside, setShowScheduleMeeting } from 'src/pages/onboarding/onboardingSlice';
import { useLocation } from 'react-router-dom';
import { PLANS } from 'src/components/Constants';
const LegalReviewRequestedSuccessfully = (props) => {
    const { email, dispatch, metadata } = props;
    const location = useLocation()
    const scheduleCall = () => {
        dispatch(setShowScheduleMeeting({ "show": true, "lawyer_details": {} }))
        dispatch(setShowRequestLegalReviewAside(false))
    }
    return (
        <>
            <div className='px-[53px] flex flex-col items-center gap-[24px] text-center'>
                <div className='w-[53px] h-[53px] mb-2j'>
                    <Check size={24} weight="bold" className="border-2 rounded-full w-[56px] h-[56px] p-2 font-bold bg-[#137513] text-white" />
                </div>
                <>
                    {location.pathname !== '/home' ?
                        <h6 className='font-sans font-semibold text-[24px] text-center text-[#353535]'>Review Requested!</h6> :
                        <h6 className='font-sans font-semibold text-[24px] text-center text-[#353535]'>Request sent successfully!</h6>
                    }
                    {!metadata?.lawyer_info ?
                        <>
                            {location.pathname === '/home' ? <p>We will connect you with a top-rated lawyer for <br />
                                guidance on your specific legal needs soon. </p> :
                                <p> We will connect you with a top-rated <br />
                                    Lawyer to address your review request.</p>}

                            <p>An e-mail has been sent on <b>{email}</b> to keep you updated on the request status.</p>
                        </> :
                        <>
                            <p>Your request has been sent to {" "}<b>{metadata?.lawyer_info?.email}</b>, and your
                                email {" "} <b>{email}</b> {" "} has been included in the email thread</p>
                            <p className='font-medium text-[14px] font-sans text-[#353535]'>
                                You can also schedule a call with them to discuss your request.
                            </p>
                        </>
                    }
                    {!metadata?.subscription_type === PLANS.PERSONAL_FREE &&
                        <>
                            {!metadata?.lawyer_matching_meeting_at &&
                                <button className='flex items-center gap-[8px] px-[24px] py-[12px] rounded-[8px] bg-[#18475A] text-white'
                                    onClick={scheduleCall}>
                                    Schedule a Call <VideoCamera size={20} />
                                </button>
                            }
                            {!metadata?.lawyer_info && <p className='text-[#353535] font-sans text-[14px] underline font-medium cursor-pointer'
                                onClick={() => { dispatch(setShowRequestLegalReviewAside(false)) }}>Close</p>}
                        </>
                    }
                </>
            </div>

        </>
    );
};

export default LegalReviewRequestedSuccessfully;