import { Lightbulb } from "@phosphor-icons/react";
import SavedDataPrompt from "../../components/Prompts/SavedDataPrompt";
import DocumentGeneratedPrompt from "../../components/Prompts/DocumentGeneratedPrompt";
import FollowupQuestionsPrompt from "../../components/Prompts/FollowupQuestionsPrompt";
import ChatMessage from "../chat/ChatMessage";
import LegalReviewMessagePrompt from "src/pages/LawyerHub/LegalReviewMessagePrompt";
import { resetPaymentStatus, setCheckoutUrl } from "src/pages/chatpage/chatSlice";
import { setShowRequestLegalReviewAside, setShowSubscriptionPopup, setShowUpgradePlanNudge } from "src/pages/onboarding/onboardingSlice";
import { setShowPersonalPlanThreadsExhausted } from "src/pages/onboarding/onboardingSlice";

import * as Sentry from "@sentry/react";
import RegeneratePrompt from "../Prompts/RegeneratePrompt";
import { setSmallScreenModalOpen } from "src/pages/Homepage/HomeSlice";
import NewDraftTypePrompt from "../Prompts/NewDraftTypePrompt";


export const getCalculatedIndices = (placeholders) => {
    var calculatedIndices = {}
    placeholders.forEach(placeholder => {
        placeholder["indices"].forEach(index => {
            calculatedIndices[index.start_index] = {
                "startIndex": index.start_index,
                "endIndex": index.end_index,
                "type": "placeholder",
                "placeholder": placeholder
            }
        });
    });
    return calculatedIndices
}


export const setBackgroundColors = (quillRef, placeholders) => {
    for (var i = 0; i < placeholders.length; i++) {
        placeholders[i]["indices"].forEach(index => {
            quillRef?.current?.editor.formatText(index.start_index, index.end_index - index.start_index, {                   // unbolds 'hello' and set its color to blue
                'background-color': 'rgba(198, 29, 202, 0.2)'
            });
        });
    }
}

export const removeBackgroundColors = (quillRef, placeholders) => {
    for (var i = 0; i < placeholders.length; i++) {
        placeholders[i]["indices"].forEach(index => {
            quillRef?.current?.editor.formatText(index.start_index, index.end_index - index.start_index, {                   // unbolds 'hello' and set its color to blue
                'background-color': 'transparent'
            });
        });
    }
}

export const setUpDocument = (data, Delta) => {
    var defaultData = new Delta();
    // insert text and attributes
    for (var i = 0; i < data.ops.length; i++) {
        if (data.ops[i].insert) {
            defaultData.insert(data.ops[i].insert, data.ops[i].attributes);
        }
    }
    return defaultData
}

export const updatePlaceholderValue = (quillRef, placeholders, placeholderIndex, updatedText, setPlaceholders) => {
    var initialValue = placeholders[placeholderIndex]["current_value"];
    placeholders[placeholderIndex]["current_value"] = updatedText;
    var indices = placeholders[placeholderIndex]["indices"];
    var stringLength = updatedText.length - initialValue.length;
    if (stringLength < 0) {
        stringLength = 0;
    }
    var forward = 0;
    var newIndices = []
    indices.forEach(index => {
        quillRef?.current?.editor.formatText(index.start_index + forward, forward, {                   // unbolds 'hello' and set its color to blue
            'background-color': 'transparent'
        });

        quillRef?.current?.editor.deleteText(index.start_index + forward, index.end_index - index.start_index);
        quillRef?.current?.editor.insertText(index.start_index + forward, updatedText);
        quillRef?.current?.editor.formatText(index.start_index + forward, index.end_index - index.start_index, {                   // unbolds 'hello' and set its color to blue
            'background-color': 'transparent'
        });
        // quillRef?.current?.editor.formatText(index.start_index + forward, updatedText.length, {                   // unbolds 'hello' and set its color to blue
        //     'background-color': 'rgba(148, 29, 202, 0.2)'
        // });

        // I think the following might not be required if we are going to get the updated indices from the backend
        var new_start_index = index.start_index + forward;
        var new_end_index = index.start_index + updatedText.length + forward;
        newIndices.push({ start_index: new_start_index, end_index: new_end_index });
        forward += stringLength;

    });
    // I think the following might not be required if we are going to get the updated indices from the backend
    // we might have to update the placeholders data with the updated text though 
    placeholders[placeholderIndex]["indices"] = newIndices
    setPlaceholders([...placeholders]);
}


export const renderMessage = (message, index, threadData, size = "full", email, threadType, sharedpage = false, setSteps) => {
    var isFirstAiMessage = false;
    if (index > 0) {
        if (threadType === "ask" || threadType === "draft") {
            if (!message.is_user_message) {
                var previousMessage = threadData.messages[index - 1];
                // with the new setup of draft data coming from the backend itself, there are chances that the first ai message is a question message, but questions are not yet set. 
                // since questions might not be set, we can't rely on the questions length to determine if the previous message was an ai message or not.
                if (previousMessage?.message_type === "draft_qna_message" && previousMessage?.questions?.length === 0) {
                    isFirstAiMessage = true;
                }
                else if (previousMessage?.user || previousMessage?.is_user_message) {
                    isFirstAiMessage = true;
                }
            }
        }
    }
    var message_type = message?.message_type;
    if (!message_type) {
        if (message.type) {
            message_type = message.type;
        }
        else if (threadType === "ask") {
            message_type = "ask_message"
        }
        else if (threadType === "draft") {
            message_type = "draft_message"
        }
        else {
            message_type = "conversation"
        }
    }
    switch (message_type) {
        case "draft_qna_message":
            var totalMessageCount = threadData.messages.length;
            if (threadData?.questions?.length > 0 || threadData?.questions?.questions?.length > 0) {
                return <>
                    <FollowupQuestionsPrompt
                        message={message}
                        messageIndex={index}
                        totalMessageCount={totalMessageCount}
                        activeQuestionIndex={threadData.questions?.active_index}
                        answers={threadData.questions?.answers}
                        skipped={threadData.questions?.skipped}
                        size={size}
                        threadType={threadType}
                        isFirstAiMessage={isFirstAiMessage}
                        email={email}
                        sharedpage={sharedpage}
                        setSteps={setSteps}
                    ></FollowupQuestionsPrompt>
                </>
            }
            return <></>
        // break;
        case "draft_message":
        case "ask_message":
        case "conversation":
            return (<><ChatMessage
                key={index}
                index={index}
                chatInfo={message}
                viewChatLinks={[]}
                message_type={message_type}
                size={size}
                email={email}
                chatId={threadData.id}
                threadData={threadData}
                isFirstAiMessage={isFirstAiMessage}
                sharedpage={sharedpage}
            />
                {/* <p>{JSON.stringify(message)}</p> */}
            </>);
        // case "conversation":
        //     return (<><Chat
        //         key={index}
        //         chatInfo={message}
        //         viewChatLinks={[]}
        //         threadType={threadData.thread_type}
        //         size={size}
        //         email={email}
        //         isFirstAiMessage={isFirstAiMessage}
        //     />
        //         {/* <p>{JSON.stringify(message)}</p> */}
        //     </>);
        case "legal_review_message":
            if (sharedpage) {
                return <></>
            }
            return (
                <><LegalReviewMessagePrompt key={index} chatInfo={message} email={email} isFirstAiMessage={isFirstAiMessage}
                    threadData={threadData} /></>
            )
        case "information_gathered_prompt":
            return (<>
                <SavedDataPrompt
                    threadType={threadData?.thread_type}
                    chatInfo={message}
                    threadId={threadData?.id}
                    size={size}
                    email={email}
                ></SavedDataPrompt >
            </>);
        case "information_message":
            return <>
                <div className="flex flex-row bg-[#faf6e8] gap-[12px] rounded-[8px] px-[10px] py-[10px] my-[15px]">
                    <span className="pt-1"><Lightbulb size={16} className="text-[#DCA218]" /></span>
                    <p className="text-[16px] font-[500] leading-[25.6px] text-[#686868]">{message?.payload?.text || message?.payload?.message_text}</p>
                </div>
            </>;
        case "regenerate_ai_response":
            return (<><RegeneratePrompt threadData={threadData} chatInfo={message} /></>);
        case "document_generated":
            return <><DocumentGeneratedPrompt
                email={email}
            /></>
        case "draft_propose_new_document_type_message":
            return <NewDraftTypePrompt threadType={threadData.thread_type} chatInfo={message} threadData={threadData} email={email} />
        default:
            <><p>Message type not found. Here is the message: {JSON.stringify(message)}</p></>
        // return <><Chat
        //     key={index}
        //     chatInfo={message}
        //     viewChatLinks={[]}
        //     threadType={threadData.thread_type}
        //     size={size}
        //     email={email}
        // /></>
    }
}

export const openPopup = (dispatch, action) => {
    dispatch(setCheckoutUrl(""))
    dispatch(resetPaymentStatus())
    dispatch(setShowRequestLegalReviewAside(false));
    if (action === "personal_free_limits_exhausted") {
        dispatch(setShowPersonalPlanThreadsExhausted(true))
        return
    }
    if (action === "legal_review") {
        dispatch(setShowSubscriptionPopup(true));
    }
    else if (action === "upgrade_plan") {
        dispatch(setShowUpgradePlanNudge(true))
    }
    else {
        // TODO: you might have to call some other nudge here
        dispatch(setShowUpgradePlanNudge(true))
    }
}


export const ErrorLogging = (error) => {

    const logError = () => {
        const email = localStorage.getItem("email")
        Sentry.withScope(scope => {
            scope.setUser({
                email: email
            });
            var response = {
                error: JSON.stringify(error),
                response: error.response ? JSON.stringify(error.response) : null,
                request: error.request ? JSON.stringify(error.request) : null,
            }
            scope.setExtra("error", error);
            scope.setExtra("more_details", response);
            Sentry.captureException(error);
        });
    };

    return logError();
}

export const openSmallScreenModal = (dispatch) => {
    dispatch(setSmallScreenModalOpen(true));
}
