import { useAuth, useUser } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useValidatePageAccess from "src/hooks/useValidatePageAccess";
import { createUserAPI, getUserMetadata, setShowUpgradePlanNudge } from '../onboarding/onboardingSlice';
import { identifyAnalytics } from '../helpers';

const PostAuthOption2 = () => {
    // post auth option 1 vs option 2
    // option 1: the first call goes to the backend to create the user. If the user is already created, then metadata is checked
    // option 2: we get metadata first. if response is 401, user is not created. call createUserAPI. 
    // if response is 200, user is created. check if user has parties. if yes, redirect to homepage. if no, redirect to onboarding.
    useValidatePageAccess();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const next = queryParams.get('next');

    const [createUserAttemptCount, setCreateUserAttemptCount] = useState(0);
    const [accessTokenValue, setAccessTokenValue] = useState(null);

    const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const userIdentified = useSelector((state) => state.onboarding.userIdentified);

    const { user, isSignedIn } = useUser();
    const { getToken } = useAuth();

    const getMyToken = async () => {
        const token = await getToken({ template: "Backend" });
        return token;
    }
    const getTokenValue = async () => {
        const token = await getMyToken();
        if (token && typeof token === "string") {
            localStorage.setItem("accessToken2", token);
            setAccessTokenValue(token);
        }
    }

    useEffect(() => {
        if (isSignedIn && user?.fullName) {
            identifyAnalytics(user.primaryEmailAddress.emailAddress, userMetadata, dispatch);
        }
    }, [isSignedIn, user, userMetadata])

    useEffect(() => {
        if (accessTokenValue) {
            dispatch(getUserMetadata())
        }
        else {
            getTokenValue()
        }
    }, [accessTokenValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // if the profile is not created (status = 401), then create the user
        // if the profile is created but profile is not completed(parties not created), (status = 404), then redirect to onboarding
        // if the profile is created and profile is completed (status = 200), then fetch parties information, and rest will be taken care of next useEffect
        if (userMetadataFetchStatus === "success") {
            switch (next) {
                case "ask":
                    navigate(`/ask`)
                    break;
                case "draft":
                    navigate(`/?open_draft=legal review request`)
                    break;
                case "review":
                    navigate(`/`)
                    break;
                case "membership":
                    dispatch(setShowUpgradePlanNudge(true))
                    navigate("/open_membership=true")
                    break
                default:
                    navigate(`/`)
            }
        }


        else if (userMetadataFetchStatus === "profile-not-completed") {

            navigate(`/onboarding?next=${next}`)
        }
        else if (userMetadataFetchStatus === "profile-not-created") {
            // TODO: redirect the user to an error page 
            alert("User not found")
            navigate("/onboarding")

        }
        // else if (userMetadataFetchStatus === "success") {
        //     dispatch(getSavedParties());
        // }
    }, [userMetadataFetchStatus]); // eslint-disable-line

    return (
        <div className="w-screen h-screen flex flex-col justify-center justify-items-center gap-[10px]">
            {/* <div className="flex flex-"> */}
            {createUserAttemptCount >= 5 ? <>
                <p className="mx-auto">We are facing some issues.</p>
                <button
                    className="cursor-pointer rounded-[8px] flex flex-row gap-[8px] items-center justify-center  text-white bg-[#302F2F] font-[600] text-[14px] py-[12px] px-[24px] my-auto mx-auto"
                    onClick={() => {
                        dispatch(createUserAPI())
                        setCreateUserAttemptCount(0)

                    }}>Retry</button>
            </> : <>
                <div className="mx-auto w-10 h-10 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
                <p className="mx-auto">Setting up your legal space.</p>
            </>}
            {/* </div> */}
        </div>
    );
};

export default PostAuthOption2;
