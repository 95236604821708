import { Briefcase, Check, Clock } from '@phosphor-icons/react';

import { useDispatch } from 'react-redux';
import { PLANS, ReviewStatus } from 'src/components/Constants';
import { setShowScheduleMeeting } from 'src/pages/onboarding/onboardingSlice';
import { setShowRequestLegalReviewAside } from 'src/pages/onboarding/onboardingSlice';
const ReviewPendingButton = ({ userMetadata, legalReview, meetingScheduled }) => {
    const dispatch = useDispatch();

    // status = accepted_by_inhouse 
    //     personal account -> lawyer assigned
    //     business account >
    //         meeting scheduled ==> review pending
    //         meeting not scheduled ==> lawyer matching pending


    return (
        <>
            {(legalReview?.status === ReviewStatus.in_progress) &&
                < button className="bg-incomplete border-[#DCA218] border-[1px] w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row">
                    <span className="my-auto"><Briefcase size={14} className="text-[#353535] my-auto" /></span>
                    <span className="text-[#353535] my-auto text-[14px] leading-[19.6px]">Review in Progress</span>
                    <span className="my-auto"><Clock size={14} className="text-[#DCA218] my-auto" /></span>
                </button>}
            {(legalReview?.status === ReviewStatus.pending_lawyer_matching) &&
                < button className="bg-incomplete border-[#DCA218] border-[1px] w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row"
                    onClick={() => {dispatch(setShowRequestLegalReviewAside(true)) }}>
                    <span className="my-auto"><Briefcase size={14} className="text-[#353535] my-auto" /></span>
                    <span className="text-[#353535] my-auto text-[14px] leading-[19.6px]">Lawyer Matching Pending</span>
                    <span className="my-auto"><Clock size={14} className="text-[#DCA218] my-auto" /></span>
                </button>
            }
        </>
    );
};

export default ReviewPendingButton;