/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import Button from '../../Button'
import LawyerCard from './LawyerCard';

export interface Step {
    stepIcon: {
        icon: any;
        color: string;
    }
    title?: {
        text: string | React.ReactNode,
        className?: string;
        extraInfo?: {
            text: string;
            color: string;
        }
    };
    description?: {
        text?: string | React.ReactNode,
        className?: string;
    }
    button?: {
        title: string;
        onClick: () => void;
        secondary?: boolean;
    },
    lawyerCard?: {
        lawyerImg: string;
        name: string;
        email: string;
        phone: string;
    },
    info?: string | React.ReactNode
}

interface VerticalProgressProps {
    steps: Step[],
    wrapperClassName: string
}

const VerticalProgress = (props: VerticalProgressProps) => {

    const {
        steps,
        wrapperClassName
    } = props;

    return (
        <div className={`${wrapperClassName}`}>
            <ul className={`relative `}>
                {/* dashed line */}
                <div className='absolute top-0 left-[19px] border-r-[2px]  border-dotted '></div>
                {/* Solid line */}
                <div className='absolute z-0 top-0 left-[19px] border-solid  border-r-[2px] '></div>
                {/* <p>{JSON.stringify(steps)}</p> */}
                {/* Items */}
                {
                    steps?.map((step: Step, index) => {
                        const Icon = step?.stepIcon?.icon;
                        const titleExtraInfo = step.title?.extraInfo;
                        return (
                            <li className={`flex gap-3 ${index > 0 && 'mt-12'}`}>
                                <div className='bg-white w-fit h-fit z-[100] rounded-full outline outline-[4px] outline-white'>
                                    <div className={`z-[100] bg-[${step.stepIcon.color}]/10 flex-none flex justify-center items-center !w-10 !h-10 rounded-full`}>
                                        <Icon className={`text-[${step.stepIcon.color}]`} size={20} />
                                    </div>
                                </div>
                                <div className='flex flex-col w-full'>
                                    {step.title && < h6 className={`text-[#011513] font-bold text-sm ${step.title.className}`}>{step.title?.text} {titleExtraInfo && <span className={`text-[${titleExtraInfo?.color}] bg-[${titleExtraInfo?.color}]/10 px-2 rounded-2xl py-1`}>{step.title?.extraInfo?.text}</span>}</h6>}
                                    {step.description && <p className={`mt-1 text-sm ${step.description.className}`}>{step.description?.text}</p>}
                                    {step.info && <p className='px-2 mt-2 w-fit text-[#353535] py-2 bg-[#FAFAFA] text-sm border rounded'>{step.info}</p>}
                                    {step.button && <Button
                                        className={'w-fit py-2 mt-2 px-3 text-sm'}
                                        text={step?.button?.title}
                                        onClick={step?.button?.onClick}
                                        secondary={step?.button?.secondary}
                                    />}
                                    {step?.lawyerCard && <LawyerCard {...step.lawyerCard} />}
                                </div>
                            </li>)
                    })
                }
            </ul >
        </div>
    )
}

export default VerticalProgress