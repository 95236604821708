import React from 'react';
import ExpertLawyerAssignedImage from "src/images/ExpertLawyerAssigned.png"

const LawyerAssigned = () => {
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-full'>
            <img src={ExpertLawyerAssignedImage} alt="Expert Lawyer Assigned" className="w-[160px] mx-auto" />
            <h1 className="text-2xl font-[600] text-[#353535]">Expert lawyer assigned</h1>
            <p className="text-base font-[400] text-center text-[#353535]">We’ve assigned a specialized lawyer to your thread. <br />
                You’ll find their contact information and next steps in your email.</p>
        </div>
    );
};

export default LawyerAssigned;