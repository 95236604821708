import React from 'react';
import { setSkipAllQuestionConfirm } from 'src/pages/chatpage/messageSlice';
import { useSelector } from 'react-redux';

const SkipAllQuestionsConfirmPopup = (props) => {
  const { submitFollowUpQuestions, dispatch } = props;
  const skipAllQuestionConfirm = useSelector((state) => state.messageSlice.skipAllQuestionConfirm);

  if (!skipAllQuestionConfirm) return null;

   const confimSkip = () => {
    dispatch(setSkipAllQuestionConfirm(false))
    submitFollowUpQuestions()
   }
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => dispatch(setSkipAllQuestionConfirm(false))}
    >
      <div
        className="w-[800px] h-[321px] p-[32px] pb-[40px] rounded-[16px] bg-white flex flex-col gap-[32px] relative"
      >
        <p className="font-sans font-semibold text-[24px] leading-[33.6px] text-[#353535]">
          Are you sure you want to skip all questions?
        </p>
        <hr className="text-[#d2d1d1] border-[2px]" />
        <p className="font-normal font-sans text-[18px] leading-[28.8px] text-[#353535]">
          The AI uses your answers to create a more accurate and tailored draft. If you skip,{' '}
          <span className="font-semibold">placeholders will be added, and general information will be used instead.</span> You
          can still edit the draft later.
        </p>
        <div className="flex gap-[16px] justify-end">
          <button
            onClick={confimSkip}
            className="text-white px-[24px] py-[12px] rounded-[8px] bg-[#18475A] font-medium text-[16px] font-sans leading-[22px]"
          >
            Proceed
          </button>
          <button
            onClick={() => dispatch(setSkipAllQuestionConfirm(false))}
            className="px-[24px] py-[12px] rounded-[8px] font-medium text-[16px] font-sans leading-[22px] border"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SkipAllQuestionsConfirmPopup;

