import React, { useEffect } from "react";
import { List } from "@phosphor-icons/react";
import { useUser } from "@clerk/clerk-react"

import { useNavigate } from "react-router-dom";
import SideNav from "../chatpage/Navs/SideNav.js"

import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";
import TryAgain from "../Homepage/TryAgain.js";

import { useDispatch, useSelector } from "react-redux";
import { setEmail } from "../Homepage/HomeSlice.js";


const BaseLayout = ({ children }) => {

    const showTryAgainPage = useSelector((state) => state.onboarding.showTryAgainPage)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoaded, user } = useUser();
    const [showSidenav, setShowSidenav] = React.useState(true);
    const [hideSidenav, setHideSidenav] = React.useState("hidden");

    const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);

    useEffect(() => {
        if (user) {
            dispatch(setEmail(user.primaryEmailAddress.emailAddress));
            setShowSidenav(true);
            if (userMetadata && userMetadataFetchStatus === "success") {
                if (!userMetadata?.individual_info && !userMetadata?.entity_info) {
                    navigate('/onboarding')
                }
            }
        }
        else {
            if (window.location.href.search("/shared/") !== -1) {
                setShowSidenav(false);
            }
        }
    }, [user, userMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

    useValidatePageAccess();

    return (
        <>
            {showTryAgainPage ? <TryAgain /> :
                <div> {isLoaded && (
                    <>
                        <div className="flex flex-row w-full">
                            {showSidenav && <SideNav hideSidenav={hideSidenav} setHideSidenav={setHideSidenav} />}
                            {hideSidenav === "hidden" && <List size={26} className="my-4 mx-2 absolute md:hidden" onClick={() => setHideSidenav("block")} />}
                            {children}
                        </div>
                    </>
                )}</div>}
        </>
    );
};

export default BaseLayout;
