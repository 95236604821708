import { ArrowLeft, ArrowRight, Briefcase, Info, PencilSimple, Plus, Users, ChatsTeardrop, LockKey } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import mLogo from '../../../../images/m.svg'
import { getDraftIcon } from 'src/pages/helpers';

const ConcernedPartyScreenComponent = (props) => {
    const dispatch = useDispatch()
    const { backtoScreen1, createDraftContinueButtonClicked, createDraft } = props;
    const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);

    const continueButton = () => {
        return createDraftContinueButtonClicked()

    }

    return (
        <>
            <div className='flex flex-col'>
                <div className='flex md:w-[960px] pt-[24px] pb-[32px] px-[40px] border-t'>
                    <div id="left" className='h-[400px] w-full md:w-[50%]'>
                        <div className='flex flex-col gap-[18px]'>
                            <p className='font-semibold text-[14px] font-sans text-[#141414]'>About {selectedDocumentType?.name}</p>
                            <p className='font-normal text-[14px] font-sans text-[#353535]'>{selectedDocumentType?.long_description}</p>
                        </div>

                        <div className=''>
                            <p className='font-medium text-[14px] mt-3 font-sans mb-2'>Also known as</p>
                            <p className="">{selectedDocumentType?.additional_search_terms?.slice(0, 1)?.map((name) => {
                                return <span className='bg-[#F2F2F280] leading-[20px] w-full px-3 py-2 rounded-[98px] font-normal cursor-pointer'> {name} </span>
                            })}</p>
                        </div>
                    </div>
                    <div id="Right" className='h-[400px] hidden md:w-[50%] border-blue-500 md:flex flex-col gap-3 px-4'>
                        <div className='w-[326px] h-[355px] bg-[#FCFCFA] relative rounded-[10px] p-[20px] flex flex-col gap-[12px]'>
                            <span>{getDraftIcon(selectedDocumentType?.icon, 32)} </span>
                            <p className='font-normal italic text-[23.08px]'>{selectedDocumentType?.name}</p>
                            <img src={mLogo} alt="m" className='absolute bottom-0' />
                        </div>

                    </div>

                </div>
                <div className="px-3">
                    <div className='md:h-[62px] gap-[8px] bg-[#FAEEFA] mx-auto py-4 pl-5 pr-4 rounded-lg my-4 flex justify-between items-center' onClick={continueButton}>
                        <ChatsTeardrop size={32} className='text-[#A843AA]' />
                        <p className='font-bold text-[14px] font-sans'>Not sure if this is the document you need? <span className='font-medium'>Ask InHouse AI—we’ll help you figure it out</span></p>
                        <p className='font-sans font-medium text-[14px] text-[#011513] px-3 py-1 rounded-lg bg-white cursor-pointer'>Ask Inhouse AI</p>
                    </div>
                </div>

            </div>

            <div className="flex justify-between items-center w-full px-[20px] md:px-[40px] pt-[16px] border-t">
                <button
                    className="bg-white border-0 text-[#18475A]  py-2 font-[600] text-[14px] md:text-[16px] md:self-end flex flex-row gap-[8px]"
                    id="document-draft-back-to-screen-1"
                    onClick={() => { backtoScreen1() }}
                >
                    <ArrowLeft size={20} className="text-[#18475A] my-auto" weight="bold" />
                    <span className="hidden md:block">Choose a different document</span>
                    <span className="md:hidden block">Change document</span>
                </button>

                <button
                    className={`bg-[#18475A] font-sans text-[14px] md:text-[16px] font-medium border-non self-end flex flex-row gap-[10px] text-white px-[24px] py-[12px] rounded-xl`}
                    id="document-draft-continue-2"
                    onClick={() => {
                        createDraft()
                    }}
                >
                    Continue <ArrowRight size={16} className="text-white my-auto" weight="bold" />
                </button>


            </div>
        </>
    );
};

export default ConcernedPartyScreenComponent;