import { Gear } from '@phosphor-icons/react';
import React from 'react';

const UnderMaintenance = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }} className="flex flex-col h-screen pt-5 items-center w-full px-10 gap-[20px]">
            <h1 className="text-[100px]"> 🚧 </h1>
            <h1 className="text-[50px]">
                We’re Improving for You!
            </h1>
            <p className="text-[20px]">We’re currently down for maintenance and upgrades as we work on launching a better experience for you.</p>
            <p className='text-[20px] font-[600]'>We’ll be back soon!</p>
            <p className='text-[20px] font-[600]'>📅 <b>Expected time:</b> 7:00 AM PST</p>
            <p className='text-[20px]'>If you have any urgent needs, feel free to reach out to us at support@inhouse.app. <br />
                Thank you for your patience and understanding.

            </p>
        </div>
    );
};

export default UnderMaintenance;