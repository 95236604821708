import { Clock } from '@phosphor-icons/react';

const CallScheduled = ({ userMetadata, email }) => {

    return (
        <button className="bg-incomplete border-[#DCA218] border-[1px] w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row">
            <span className="text-black my-auto text-[14px] leading-[19.6px]">Lawyer Matching in Progress</span>
            <span className="my-auto"><Clock size={14} className="text-[#DCA218] my-auto" /></span>
        </button>
    );
};

export default CallScheduled;