// import logo from './logo.svg';
import "./App.css";
// import React, { useEffect, useReducer, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { RequireAuth } from "./persistence/middlewares/AuthMiddleware";
// import ChatPage from "./pages/chatpage/ChatComponent";
import { Provider } from "react-redux";
import { ClerkProvider } from '@clerk/clerk-react'
import RootStore from "./persistence/stores/RootStore";

import Homepage from "./pages/Homepage/Home";

import AskPage from "./pages/chatpage/AskPage";

// import OnboardingPage from "./pages/onboarding/OnboardingPage";

import NotFoundPage from "./pages/chatpage/NotFoundPage";
import Shared from "./pages/chatpage/Shared";

import DocumentViewer from "./components/DocumentViewer/index";

import ChatPageComponent from "./pages/chatpage/ChatPageComponent";
import Signin from "./pages/UserAuthentication/Signin";
import Signup from "./pages/UserAuthentication/Signup";
import { WebSocketProvider } from './WebsocketProvider.js';
import LawyerHub from "./pages/LawyerHub";
import MobileHome from "./pages/mobileHome";
import PostAuthOption2 from "./pages/post-auth/index2";
import IframeTest from "./pages/chatpage/Modals/PaymentModal/iframttest";
import OnboardingBase from "./pages/onboardingV2/base";
import * as Sentry from "@sentry/react";
import UnderMaintenance from "./pages/UnderMaintenance";


function App() {

  const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const updating = process.env.REACT_APP_WEBSITE_UNDER_MAINTENANCE === 'true';
  const localization = {
    signIn: {
      start: {
        title: 'Sign in to your account',
        subtitle: 'Welcome back! Please enter your details',
      },

    },
  };
  const RequireQueryParam = ({ children }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const requiredParam = queryParams.get('requiredParam');

    if (!requiredParam) {
      return <Navigate to="/home" />;
    }

    return children;
  }

  return (
    <ClerkProvider
      localization={localization}
      publishableKey={PUBLISHABLE_KEY}
      afterSignOutUrl={process.env.REACT_APP_POST_SIGNOUT_URL}
      appearance={{
        variables: {
          colorPrimary: '#1B2B48',
          colorBackground: 'white'
        },
      }}
    >
      <Provider store={RootStore}>
        {/* <WebSocketProvider url={process.env.REACT_APP_WEBSOCKET_URL}> */}
        <WebSocketProvider>
          <Router> {/* router = BrowserRouter */}
            <SentryRoutes>
              {updating ?
                <Route
                  path="*"
                  element={
                    <UnderMaintenance />
                  }
                />
                :
                <>
                  <Route path="/document/:document-id" element={<DocumentViewer />} />
                  <Route path="/post-auth" element={<PostAuthOption2 />} />

                  <Route
                    path="/"
                    element={
                      <Navigate to="/home" />
                    }
                  />
                  <Route
                    path="/signin"
                    element={
                      <Signin />
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <Signup />
                    }
                  />
                  <Route
                    path="/home"
                    element={
                      <Homepage />
                    }
                  />

                  <Route
                    path="/ask"
                    element={
                      <AskPage />
                    }
                  />

                  <Route
                    path="/draft"
                    element={
                      <ChatPageComponent />
                    }
                  />


                  <Route
                    path="/:threadType/:chatId"
                    element={
                      <ChatPageComponent />
                    }
                  />
                  <Route
                    path="/onboarding"
                    element={
                      <OnboardingBase />
                      // <OnboardingPage />
                    }
                  />

                  <Route
                    path="/not-found"
                    element={
                      <NotFoundPage />
                    }
                  />
                  <Route
                    path="/:threadType/shared/:chatId"
                    element={
                      <Shared />
                    }
                  />
                  <Route
                    path="/lawyer-hub"
                    element={
                      <LawyerHub />
                    }
                  />
                  <Route
                    path="/mobile-home"
                    element={
                      <MobileHome />
                    }
                  />
                  <Route path="*" element={<Navigate to="/not-found" />} />
                </>}
              {/* </Routes> */}
            </SentryRoutes>
          </Router>
        </WebSocketProvider>
      </Provider>
    </ClerkProvider>
  );
}

export default App;
