import React from 'react';
import OngoingLawyerReviewImage from "src/images/OngoingLawyerReview.png"

const OngoingReview = () => {
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-full'>
            <img src={OngoingLawyerReviewImage} alt="Expert Lawyer Assigned" className="w-[160px] mx-auto" />
            <h1 className="text-2xl font-[600] text-[#353535]">Lawyer review is ongoing</h1>
            <p className="text-base font-[400] text-center text-[#353535]">A specialized lawyer has been assigned to this thread. <br />
                Please check your email for the review details.</p>
        </div>
    );
};

export default OngoingReview;