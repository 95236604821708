import React from 'react';
import LegalReviewRequestedSuccessfully from '../LegalReviewRequestedSuccessfully';
import { ReviewStatus } from 'src/components/Constants';
import RequestReviewFormAside from '../RequestReviewFormAside';
import LegalReviewCompleteAsidePersonal from './LegalReviewCompleteAsidePersonal';

const LegalReviewRequestPersonal = (props) => {
    const { email, trackAnalytics, dispatch, metadata, legalReiviewImg, requestLegalReviewStatus, text, setText, loading, isPhone, setIsPhone, setShowLearnMoreModal, phoneNumber, setPhoneNumber, legalReview } = props;
    return (
        <>
            {/* // personal profile: 3 cases :
                // 1. review is just requested successfully. we show success 
                // 2. review is not yet requested. we show the form
                // 3. review is in progress. we show the in progress screen
                // 4. review is completed. we show the completed screen

                // case 6 : review requested successfully: personal user 
            */}
            {(requestLegalReviewStatus === "success") ?
                <LegalReviewRequestedSuccessfully
                    metadata={metadata}
                    dispatch={dispatch}
                    email={email}
                />
                :
                <> {
                    (legalReview) ?
                        <>
                            {/* // review already requested */}
                            {(legalReview?.status === ReviewStatus.in_progress) ?
                                <LegalReviewRequestedSuccessfully email={email} dispatch={dispatch} metadata={metadata} />
                                :
                                // review requested and is completed
                                <LegalReviewCompleteAsidePersonal />
                            }
                        </> :
                        // legal review is not yet requested
                        <RequestReviewFormAside
                            email={email}
                            trackAnalytics={trackAnalytics}
                            dispatch={dispatch}
                            inputMandatory={false}
                            text={text}
                            setText={setText}
                            loading={loading}
                            isPhone={isPhone}
                            setIsPhone={setIsPhone}
                            setShowLearnMoreModal={setShowLearnMoreModal}
                            legalReiviewImg={legalReiviewImg}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                        />

                }
                </>}
        </>);
};

export default LegalReviewRequestPersonal;