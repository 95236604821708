import { Export } from '@phosphor-icons/react';

const ShareButton = ({ shareThread }) => {

    return (
        <button className="bg-white border w-[40px] h-[48px] rounded-[8px] " onClick={shareThread}>
            <Export size={13} className="mx-auto text-[#353535]" />
        </button>
    );
};

export default ShareButton;