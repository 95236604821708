import { Sparkle, FolderLock, Briefcase, Bank, Building, Certificate, Receipt, MagnifyingGlass, HandDeposit, Note, Users, Medal, Pen, GitDiff, Cpu, Signature, LockKey, CalendarDots, Scroll, Copyright, FileMagnifyingGlass, HouseLine, Key, Handshake, ChartLineUp, Clipboard, Scales, Gavel, Envelope, FileText, ArrowRight } from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import WhiteRightArrowImage from "../../../../images/icons/arrow-right-white.svg";
import { Lock } from "@phosphor-icons/react";
import docLogo from '../../../../images/document.svg'
import { Trademark } from "@phosphor-icons/react/dist/ssr";
import { getDraftIcon } from "src/pages/helpers";
const DocumentDetailsScreenComponent = (props) => {
  const {
    documentType,
    // setDocumentType,
    draftTypeSuggestions,
    continueToConcernedParties,
    handleChange,
    isDropdownVisible,
    filteredSuggestions,
    handleSuggestionClick,
    documentSuggestionClicked,
    browseAllClicked,
    onDropDownBlur,
    draftTypes,
    createDraftContinueButtonClicked,
    setSelectedDraft

  } = props;

  const [categories, setCategories] = useState(['For You']);
  const [selectedCategory, setSelectedCategory] = useState('For You')


  const getCategoryIcon = (category) => {
    switch (category) {
      case 'For You':
        return <Sparkle size={16} className="text-[#248370]" />;
      case 'Commercial Agreement':
        return <Receipt size={16} />;
      case 'Employment Agreement':
        return <Briefcase size={16} />;
      case 'Corporate Documents':
        return <Certificate size={16} />;
      case 'Letter':
        return <FolderLock size={16} />;
      case 'Real Estate':
        return <Building size={16} />;
      default:
        return <Lock size={16} />;
    }
  };

  const helpMeDecide = (bg = "bg-[#748A8C1F]") => {
    return <>
      <div className={` h-[48px] rounded-lg border-2 z-4 flex items-center gap-[12px] py-[12px] pl-[16px] pr-[24px] mt-2 cursor-pointer ${bg}`} onClick={createDraftContinueButtonClicked}>
        <Sparkle size={20} weight="fill" className="text-[#748A8C]" />
        <p className="font-medium text-[14px] font-sans text-[#141414] flex-1">Don’t see what you need? Draft a custom document.</p>
        <ArrowRight size={20} className="" />
      </div>
    </>
  }

  useEffect(() => {
    if (draftTypes) {
      const uniqueCategories = [
        ...new Set(draftTypes.map((draft) => draft.category))
      ];
      setCategories(['For You', ...uniqueCategories]);
    }
  }, [draftTypes]);

  const randomName = React.useState(Math.random().toString(36).substring(2, 15));
  const isLoading = !draftTypeSuggestions || draftTypeSuggestions.length === 0;
  return (
    // <></>

    <>
      <div className="flex">
        <div id="left Side" className="hidden md:flex w-[200px] px-[8px] min-h-[500px]">

          <div id="Categories" className=" flex flex-col gap-[16px]" >
            {categories.map((category, idx) => {
              return <>
                <p className={`px-[12px] py-[8px] rounded-[60px] hover:bg-[#F2F2F280] w-[200px] flex gap-[8px] cursor-pointer ${selectedCategory === "For You" && selectedCategory === category ? 'bg-[#ECF5F3] ' : ''} ${(selectedCategory !== "For You" && selectedCategory === category ? 'bg-[#F2F2F280]' : '')} ${idx === 0 && 'h-[48px] hover:bg-[#ECF5F3]'}`}
                  onClick={() => setSelectedCategory(category)}>
                  <span className="my-auto ">
                    {getCategoryIcon(category)}
                  </span>
                  <span className="my-auto text-[#18475A] font-sans text-[12px] font-medium">{category}</span>
                </p></>
            })}
          </div>
        </div>
        <div id="Right side" className="flex flex-col justify-between px-[24px] gap-[32px] md:w-[100%]  ">
          <div className="flex flex-col gap-[24px]">
            <div className="flex flex-col gap-[16px]">
              {/* <div className="flex flex-col ">
            <h3 className="text-[18px] text-[#1414114] leading-[28.8px] font-[600]">What would you like to draft?</h3>
            <p className="text-[14px] font-[400] leading-[21px] text-[#686868]">
              If you're unsure of the document name, just describe what you need in plain English
            </p>
          </div> */}
              <div className="relative flex flex-col">
                <input
                  type="text"
                  id="document-type-input"
                  className="border border-[#E0E0E0] focus:border-[#18475A] pl-10 rounded w-full h-[56px] px-[16px] text-[14px] leading-[24px]"
                  placeholder="Search for a document type (e.g., NDA, Lease Agreement)"
                  value={documentType?.name}
                  name={randomName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={onDropDownBlur}
                />
                <MagnifyingGlass
                  size={20}
                  className="absolute left-[12px] top-[50%] transform -translate-y-[50%] text-[#18475A]"
                />

                {isDropdownVisible && (
                  <div className="absolute w-full mt-[55px] z-10 pb-[10px]">
                    {filteredSuggestions.length === 0 ? (
                      helpMeDecide("bg-[#FFFFFF]")
                    ) : (
                      <div className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg pb-[10px]">
                        <div className="px-4 py-2 mt-[8px] flex flex-row gap-[12px]">
                          <Sparkle size={16} className="my-auto text-[#18475A]" />
                          <span className="text-[#748A8C] text-[14px] font-[600] my-auto">Suggestions</span>
                        </div>
                        <ul className="max-h-[250px] overflow-y-scroll">
                          {filteredSuggestions.slice(0, 7).map((suggestion, index) => (
                            <li
                              key={index}
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

              </div>
            </div>
            <div className="flex flex-col gap-[20px]">
              <div className="flex flex-col w-full bg-white gap-[24px]">
                {isLoading && (
                  <div className="w-full flex justify-center items-center flex-col  py-[40px]">
                    <div className="w-6 h-6 border-2 border-t-0 border-black rounded-full animate-spin"></div>
                    <div className="">Fetching suggestions...</div>
                  </div>
                )}



                {/** Inside the Filtereed Category section **/}
                <div className=" justify-start items-center w-full gap-x-[24px] gap-y-[14px] max-h-[350px] overflow-scroll grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 ">
                  {!isLoading &&
                    draftTypeSuggestions
                      .filter((suggestion) =>
                        selectedCategory === "For You" || suggestion.category === selectedCategory
                      )
                      .map((suggestion, index) => (
                        <div
                          className={`flex flex-col justify-start border px-[16px] py-[16px] h-[126px] md:h-[106px] gap-[8px] cursor-pointer rounded-lg shadow-sm ${documentType?.id === suggestion.id
                            ? "!border-[#248370] text-[#000] bg-[#ECF5F3]"
                            : "border-[#E4E4E4]"
                            }`}
                          key={index}
                          onClick={() =>
                            documentSuggestionClicked({ suggestion: suggestion, index: index })
                          }
                        >
                          <span className=" h-[16px]">
                            {/* {suggestion.icon || <Briefcase size={16} />} */}
                            {getDraftIcon(suggestion.icon, 16)}
                          </span>
                          <p className="text-[#353535] text-[14px] leading-[24px] font-[500]">
                            {suggestion.name}
                          </p>
                        </div>
                      ))}
                  {selectedCategory &&
                    !isLoading &&
                    draftTypeSuggestions.filter(
                      (suggestion) =>
                        selectedCategory === "For You" || suggestion.category === selectedCategory
                    ).length === 0 && (
                      <p className="text-[#686868] text-[14px] leading-[24px] font-[400]">
                        No suggestions available for {selectedCategory}.
                      </p>
                    )}
                </div>
              </div>

              {/* {Filtered Category End} */}

            </div>
          </div>

          {helpMeDecide()}
          {/* <div className="w-[712px] bg-[#10705D] h-[100px] rounded-xl flex items-center gap-4 px-[12px] mt-10 cursor-pointer" onClick={createDraftContinueButtonClicked}>
          <div className="h-full flex items-end">
            <img src={docLogo} alt="doc" />
          </div>
          <div className="h-full flex flex-col justify-center relative">
            <p className="font-medium font-sans text-[#FCFCFA] text-[20px] line-h-[28px]">Can't find what you need?  Unsure of the right document?</p>
            <p className="font-sans font-normal text-[18px]  text-[#ECF5F3]">Describe it to us, our AI will help draft it.</p>
            <div className="absolute right-0 bottom-3 cursor-pointer">
            </div>
          </div>

        </div> */}
        </div>
      </div>
    </>
  );
};

export default DocumentDetailsScreenComponent;
