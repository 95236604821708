import { ArrowUpRight, Briefcase, CalendarCheck, EnvelopeSimple, User } from '@phosphor-icons/react'
import React, { useEffect } from 'react'
import VerticalProgress from '../components/VerticalProgress'


const LawyerMatching = ({ userMetadata }) => {
    const [steps, setSteps] = React.useState([])
    const getSteps = () => {
        setSteps([
            {
                stepIcon: {
                    icon: CalendarCheck,
                    color: '#137513'
                },
                description: {
                    text: <>1-on-1 with our legal support team on<span className='font-semibold'> {new Date(userMetadata?.lawyer_matching_meeting_at).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}</span> </>
                },
                button: {
                    title: <>View Meeting Details <ArrowUpRight className='ml-2' size={20} /> </>,
                    onClick: () => { },
                },
            },
            {
                stepIcon: {
                    icon: Briefcase,
                    color: '#949494'
                },
                title: {
                    text: 'Lawyer gets matched'
                },
                description: {
                    text: <>You'll receive an email as soon as we match you with the best lawyer for your needs. </>
                },
            },
        ])
    }
    useEffect(() => {
        getSteps()
    }, [])
    return (
        <div className='pt-12'>

            <div className='flex items-center justify-center'>
                <div className='px-3 py-3 bg-gray-200 rounded-full w-fit'>
                    <User className='self-center' size={24} />
                </div>
            </div>

            <p className='px-6 mt-4 text-[#011513] font-semibold text-lg text-center'>{userMetadata?.lawyer_info?.name}</p>
            <p className='px-6 mt-1 text-sm font-normal text-[#353535] text-center'>You’ve started the lawyer matching process. <br />Lawyer’s details will be displayed here once assigned.</p>

            <hr className='px-6 mt-10 text-gray-300 ' />

            <div className='px-6 py-2'>
                <h6 className='text-sm font-semibold text-[#353535]'>Upcoming</h6>
                <VerticalProgress wrapperClassName='mt-6' steps={steps} />
            </div>




        </div>
    )
}

export default LawyerMatching