import { Dropdown, Space } from "antd";

// import IHLogo from "../../../images/ih-logo-small-v2.png";
import IHLogo from "../../../images/Inhouse-logos/Inhouse logos_PNG/favicon-blue.png"
import IHLogoLarge from "../../../images/inhouse-logo-v2.png"

import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';

import { Provider, useDispatch, useSelector } from "react-redux";
import { getPortalSession, resetPortalSessionUrl } from "../../UserAuthentication/AuthenticationSlice.js";
import { getSavedParties, setViewChatHistory, getAllDraftTypes, getEducationMetadata, updateSidenavCollapse, setSmallScreenModalOpen, resetCreateNewPartyStatus } from '../../../pages/Homepage/HomeSlice.js';

import { SignOutButton, useAuth, useUser } from "@clerk/clerk-react";
import ChatHistory from "./ChatHistory.js";
import { useEffect, useRef, useState } from "react";
import { getAllThreads } from "../threadsSlice.js";
import { ArrowLineLeft, ArrowLineRight, Briefcase, ChatsCircle, ClockCounterClockwise, FileMagnifyingGlass, House, IdentificationCard, PenNib, Plus, Question, ArrowLeft } from "@phosphor-icons/react";
import { useClerk } from '@clerk/clerk-react';
import applicationStore from "../../../persistence/stores/RootStore.js";
import { createUserAPI, getLawyerHub, getUserMetadata, setShowRequestLegalReviewAside, } from "../../onboarding/onboardingSlice.js";
import ToastHandler from "./ToastHandler.js";

import PlanSelectionPopup from "src/pages/LawyerHub/PlanSelectionPopup";
import useValidatePageAccess from "src/hooks/useValidatePageAccess";
import PaymentModal from "../Modals/PaymentModal";
import LawyerCard from "src/pages/LawyerHub/LawyerCard";
import { setDraftDocumentModalOpen, setDraftModalDraftType } from "src/modalSlice";
import DraftDocumentQuestionaireModal from "../Modals/DraftDocumentQuestionaireModal";
import { resetRedirectTo, setAskToDraftIntentWSResponse } from "../messageSlice";
import { openPopup } from "src/components/DocumentViewer/helpers";
import UpSellFlowPopup from "src/pages/LawyerHub/UpsellFlowPopup";
import { clearTokens, trackAnalytics } from "src/pages/helpers";
import ReviewRequested from "src/pages/LawyerHub/ReviewRequested";
import LawyerReviewRequested from "src/pages/LawyerHub/LawyerReviewRequestedProgress";
import LegalReviewComplete from "src/pages/LawyerHub/LegalReviewComplete";
import OnboardingV2 from "src/pages/onboardingV2";
import { toast } from "react-toastify";
import { PLANS } from "src/components/Constants";
import PersonalAccountThreadsExhausted from "src/pages/LawyerHub/UpsellFlowPopup/PersonalAccountThreadsExhausted";
import { setShowLawyerCard } from "src/pages/onboarding/onboardingSlice";
import SupportPopup from "./SupportPopup";
import LegalReviewModals from "src/pages/LawyerHub/LawyerModals";
import { resetThreadData } from "../messageSlice";
import SmallScreenModalComponent from "src/components/chat/Modals/SmallScreenModal";


const SideNav = ({ subscriptionType, hideSidenav, setHideSidenav }) => {
    useValidatePageAccess();

    const [smallScreen, setSmallScreen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startNewChatDropdownVisible, setStartNewChatDropdownVisible] = useState(false)
    const [isPopupVisible, setPopupVisible] = useState(false);
    const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
    const viewChatHistory = useSelector((state) => state.homeslice.viewChatHistory);
    const email = useSelector((state) => state.onboarding.email);
    const redirectTo = useSelector(state => state.messageSlice.redirectTo);

    const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
    const showUpgradePlanNudge = useSelector((state) => state.onboarding.showUpgradePlanNudge);
    const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
    const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
    const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
    const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
    const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
    const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
    const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
    const showReviewRequestedModal = useSelector((state) => state.onboarding.showReviewRequestedModal);
    const showLegalReviewRequestStatus = useSelector((state) => state.onboarding.showLegalReviewRequestStatus);
    const showLegalReviewCompletedModal = useSelector((state) => state.onboarding.showLegalReviewCompletedModal);
    const portalSessionUrl = useSelector((state) => state.auth.portalSessionUrl);
    const educationMetadataFetchStatus = useSelector((state) => state.homeslice.educationMetadataFetchStatus);
    const draftTypesStatus = useSelector((state) => state.homeslice.draftTypesStatus);
    const showPersonalPlanThreadsExhausted = useSelector((state) => state.onboarding.showPersonalPlanThreadsExhausted);
    const savedPartiesFetchStatus = useSelector((state) => state.homeslice.savedPartiesFetchStatus);
    const showLawyerCard = useSelector((state) => state.onboarding.showLawyerCard);
    const showLawyerAssignedModal = useSelector((state) => state.onboarding.showLawyerAssignedModal);
    const showOngoingReviewModal = useSelector((state) => state.onboarding.showOngoingReviewModal);
    const showReviewCompleteModal = useSelector((state) => state.onboarding.showReviewCompleteModal);
    const smallScreenModalOpen = useSelector((state) => state.homeslice.smallScreenModalOpen);
    const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);

    const { user } = useUser();
    const { getToken } = useAuth();

    const draftDocumentModalRef = useRef(null);
    const { openUserProfile } = useClerk();

    const getPortalSessionUrl = () => {
        dispatch(getPortalSession());
    }

    const changeSidenavCollapse = () => {
        dispatch(updateSidenavCollapse(!sidenavCollapsed));
    }


    const visitLawyerHub = () => {
        trackAnalytics("Lawyer Hub clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress }, userMetadata);
        // window.analytics.track("Lawyer Hub clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
        navigate("/lawyer-hub");
    }

    const goToSomePlace = (suggestion) => {
        dispatch(setAskToDraftIntentWSResponse({}))
        if (userMetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
            if (userMetadata?.threads_remaining === 0) {
                if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE) {
                    openPopup(dispatch, "personal_free_limits_exhausted");
                }
                else {
                    openPopup(dispatch, "upgrade_plan");
                }
                return;
            }
        }
        switch (suggestion.type) {
            case "ask":
                trackAnalytics("Ask Icon clicked from sidebar", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress }, userMetadata);
                // window.analytics.track("Ask Icon clicked from sidebar", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                dispatch(resetThreadData())
                navigate("/ask?clear=true");
                break;
            case "draft":
                if (smallScreen) {
                    dispatch(setSmallScreenModalOpen(true));
                    return;
                }
                trackAnalytics("draft Icon clicked from sidebar", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress }, userMetadata);
                // window.analytics.track("draft Icon clicked from sidebar", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                openDraftDocumentModal("side nav");
                break;
            default:
                break;
        }
    }


    const items = [
        {
            label: "My Account",
            key: "0",
        },
        {
            label: "Logout",
            key: "2",
        },
    ];

    const handleChatHistoryClicked = () => {
        trackAnalytics("dashboard_home_history_tab_clicked", { "user_email": user?.primaryEmailAddress?.emailAddress }, userMetadata);
        dispatch(setViewChatHistory(true));
    }
    const openDraftDocumentModal = (source, draft) => {
        trackAnalytics(`Draft document from ${source}`, { "user_email": user?.primaryEmailAddress?.emailAddress }, userMetadata);
        // window.analytics.track(`Draft document from ${source}`, { "user_email": user?.primaryEmailAddress?.emailAddress });
        dispatch(setDraftModalDraftType(draft))
        // navigate(`/?open_draft=${message}`)
        // dispatch(setDraftModalDraftType(message))
    }

    useEffect(() => {

        setTimeout(() => {
            dispatch(getUserMetadata())
        }, 1000)
        if (educationMetadataFetchStatus === "idle" || educationMetadataFetchStatus === "failed") {
            dispatch(getEducationMetadata())
        }
        dispatch(getLawyerHub())
        dispatch(getAllThreads())
        if (window.innerWidth < 768) {
            dispatch(updateSidenavCollapse(true));
            (setSmallScreen(true));
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (userMetadataFetchStatus === "profile-not-completed") {
            navigate("/onboarding?utm_source=signup")
        }
        else if (userMetadataFetchStatus === "profile-not-created") {
            dispatch(createUserAPI())
            navigate("/onboarding?utm_source=signup")
        }
        else if (userMetadataFetchStatus === "success") {
            if (draftTypesStatus === "idle" || draftTypesStatus === "failed") {
                dispatch(getAllDraftTypes());
            }
            if (savedPartiesFetchStatus === "idle" || savedPartiesFetchStatus === "failed") {
                dispatch(getSavedParties());
            }
        }
    }, [userMetadataFetchStatus]); // eslint-disable-line

    useEffect(() => {
        if (redirectTo) {
            var redirectUrl = redirectTo;
            dispatch(resetRedirectTo(null));
            dispatch(setDraftDocumentModalOpen(false))
            navigate(redirectUrl);
        }
    }, [redirectTo]); // eslint-disable-line

    useEffect(() => {

        if (portalSessionUrl) {
            const newTab = window.open(portalSessionUrl, "_blank");

            function checkIfTabClosed() {
                if (newTab.closed) {
                    setTimeout(() => {
                        dispatch(getUserMetadata());
                    }, 2000);
                    clearInterval(checkInterval);
                }
            }

            dispatch(resetPortalSessionUrl())
            const checkInterval = setInterval(checkIfTabClosed, 1000);

        }
    }, [portalSessionUrl]); // eslint-disable-line

    useEffect(() => {
        if (userMetadata.show_new_lawyer_assigned_notification) {
            dispatch(setShowLawyerCard(true));
        }
    }, [userMetadata]) // eslint-disable-line

    useEffect(() => {
        dispatch(setShowRequestLegalReviewAside(false))
    }, [window.location.href]) // eslint-disable-line

    useEffect(() => {
        if (requestLegalReviewStatus === "success") {
            dispatch(getLawyerHub())
            // if (metadata.subscription_type === PLANS.FREE_TIER) {
            //     openPopup(dispatch, "legal_review");
            // }
            // else if (metadata.subscription_type === PLANS.PERSONAL_FREE) {
            //     dispatch(setShowFindBestLawyer(true))
            // }
            // else if (metadata.subscription_type === PLANS.SUBSCRIBER_BUSINESS) {
            //     if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
            //         openPopup(dispatch, "legal_review");
            //     }
            // }
            // else if (metadata.lawyer_info) {
            //     if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
            //         openPopup(dispatch, "legal_review");
            //     }
            //     else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
            //     }
            // }
        }
    }, [requestLegalReviewStatus]) // eslint-disable-line



    return (
        <>
            <div className="bg-white z-2">
                <div
                    className={`side-nav ${sidenavCollapsed === true ? "small" : "large"} h-screen overflow-y-scroll top-0
                flex py-[24px] px-[0px] flex-col justify-between left-0 ${hideSidenav} md:flex absolute md:relative`}
                >
                    <div className="relative flex flex-col gap-6 side-nav-threads align-end">
                        <div className="sidebar-first-section flex flex-col gap-y-[40px] ">
                            <ul className="flex flex-col p-[8px] gap-[16px]">
                                <li><ArrowLeft size={20} className="md:hidden" onClick={() => setHideSidenav('hidden')} /> </li>
                                <li className="h-[32px] flex flex-col" onClick={() => { navigate("/home") }}>
                                    {sidenavCollapsed ?
                                        <img src={IHLogo} className="w-[100px] h-[42px] object-contain" alt="Inhouse" />
                                        :
                                        <img src={IHLogoLarge} className="w-[100px] h-[42px] object-contain" alt="Inhouse" />
                                    }
                                </li>
                            </ul>
                            <ul className="flex flex-col p-[8px] gap-[24px]">

                                <Dropdown
                                    trigger={["click"]}
                                    open={startNewChatDropdownVisible}
                                    onOpenChange={(open) => setStartNewChatDropdownVisible(open)}
                                    dropdownRender={() => <div className="absolute left-0 py-2 bg-transparent group-hover:block top-full min-w-fit ">
                                        <div className="bg-white border shadow-md rounded-xl">
                                            <div className="text-[#141414] text-base font-medium whitespace-nowrap px-4 py-3 w-[235px]">Start A New Task</div>
                                            <ul className="flex flex-col py-2 select-none">
                                                <li className="flex flex-row w-full cursor-pointer text-[#686868] py-2 items-center text-sm font-normal px-4 justify-start"
                                                    onClick={() => {
                                                        goToSomePlace({ 'type': 'ask' });
                                                        setStartNewChatDropdownVisible(false)
                                                    }}
                                                ><span><ChatsCircle className="mr-2" /></span> Ask Inhouse AI </li>
                                                <li className="flex flex-row w-full cursor-pointer text-[#686868] py-2 items-center text-sm font-normal px-4 justify-start"
                                                    onClick={() => {
                                                        goToSomePlace({ 'type': 'draft' });
                                                        setStartNewChatDropdownVisible(false)
                                                    }}
                                                ><PenNib className="mr-2" /> Draft Legal Document </li>
                                                <li className="flex flex-row w-full cursor-pointer text-[#686868] py-2 items-center text-sm font-normal px-4 justify-start"
                                                    onClick={() => {
                                                        // goToSomePlace();
                                                        setStartNewChatDropdownVisible(false)
                                                    }}
                                                ><FileMagnifyingGlass className="mr-2" /> Scan for Red Flags </li>
                                            </ul>
                                        </div>
                                    </div>}
                                >
                                    <li className={`relative group flex items-center rounded-full ${sidenavCollapsed ? '' : '-left-2 bg-[#748A8C14] px-1 py-1'}`} onClick={() => {
                                        trackAnalytics("Start new chat clicked",
                                            { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress },
                                            userMetadata);
                                        // window.analytics.track("Start new chat clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                                        // navigate("/")
                                    }}>
                                        <div className={`w-6 h-6 flex justify-center items-center rounded-full ${sidenavCollapsed ? `${startNewChatDropdownVisible ? 'bg-[#353535]' : 'bg-[#748A8C14]'}` : `${startNewChatDropdownVisible ? 'bg-[#353535]' : 'bg-white'}`}`}>
                                            <Plus
                                                size={16}
                                                weight={`bold`}
                                                className={` ${startNewChatDropdownVisible ? 'text-white' : 'text-[#353535]'}`}
                                            />
                                        </div>
                                        {!sidenavCollapsed && <div className={`inner ${window.location.pathname === "/home" ? "active" : ""} text-center`}>
                                            <p className="text-[12px] ">Start New Task</p>
                                        </div>}

                                    </li>
                                </Dropdown>

                                <li className="" onClick={() => {
                                    trackAnalytics("Home clicked",
                                        { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress },
                                        userMetadata);
                                    // window.analytics.track("Home clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                                    navigate("/home")
                                }}>
                                    <House size={16} weight={`${window.location.pathname === "/home" ? "fill" : "fill"}`}
                                        className={`${window.location.pathname === "/home" ? "text-[#18475A]" : "text-[#18475A]"}`} />
                                    <div className={` ${window.location.pathname === "/home" ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Home</p>
                                    </div>
                                </li>

                                <li className="" onClick={handleChatHistoryClicked}>
                                    <ClockCounterClockwise size={16} weight={`${viewChatHistory ? "fill" : "regular"}`}
                                        className={`${viewChatHistory ? "text-[#18475A]" : "text-[#686868]"}`} />
                                    <div className={`${viewChatHistory ? "active" : ""} text-center`}>
                                        <p className="text-[12px] flex flex-row gap-[4px]"><span className="inner">Task</span> History</p>
                                    </div>
                                </li>
                                {userMetadata?.entity_info && !smallScreen &&
                                    <li className="" onClick={visitLawyerHub}>
                                        <Briefcase size={16} weight={`${window.location.pathname === "/lawyer-hub" ? "fill" : "regular"}`}
                                            className={`${window.location.pathname === "/lawyer-hub" ? "text-[#18475A]" : "text-[#686868]"}`} />
                                        <div className={` ${window.Location.pathname === "/lawyer-hub" ? "active" : ""} text-center`}>
                                            <p className="text-[12px] ">Lawyer Hub </p>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                        {isPopupVisible && <SupportPopup isVisible={isPopupVisible} onClose={() => setPopupVisible(false)} />}

                    </div>
                    <div className="flex flex-col gap-[8px] side-nav">
                        <ul className="flex flex-col p-[8px] gap-[20px]">
                            <li className="flex py-[8px] gap-[8px] text-[#686868] cursor-pointer" onClick={() => setPopupVisible(true)}>
                                <Question size={16} />
                                <div className="">
                                    <p className="text-[12px] font-medium font-sans leading-[18px]">
                                        {sidenavCollapsed ? "Help" : "Contact Help"}</p>
                                </div>
                            </li>
                            {/* <li className="" onClick={handleChatHistoryClicked}>
                                <Question size={16} weight="thin" className="text-[#949494]" />
                                <div className={`inner ${window.Location.pathname === "/lawyer-hub" ? "active" : ""} text-center`}>
                                    <p className="text-[12px] ">Lawyer Hub </p>
                                </div>
                            </li> */}
                            <li className=""><Dropdown
                                overlayStyle={{ zIndex: 40 }}
                                dropdownRender={(menu) => (
                                    <div className="nav-footer-dropdown flex flex-col items-start w-full gap-4 p-4 bg-white rounded-[5px] border">
                                        <ul>
                                            {(userMetadata?.subscription_type === PLANS.SUBSCRIBER_BUSINESS || userMetadata?.subscription_type === PLANS.SUBSCRIBER_ENTERPRISE) ?
                                                <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px] cursor-pointer">
                                                    <span
                                                        type="text"
                                                        className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"
                                                        id="navigation-account"
                                                        // href={portalSessionUrl} data-cb-type="portal"
                                                        onClick={getPortalSessionUrl}
                                                        target="blank"
                                                    >
                                                        Manage Subscription
                                                    </span>
                                                </li> : ""}
                                            {items.map((item) => (
                                                <>
                                                    {item.label !== "Logout" ? (
                                                        <li onClick={() => {
                                                            if (item.label === "My Account") {
                                                                dispatch(resetCreateNewPartyStatus())
                                                                // openUserProfile(myUserProfile);
                                                                openUserProfile(
                                                                    {
                                                                        customPages: [
                                                                            {
                                                                                label: 'Onboarding',
                                                                                url: '/custom-page-1',
                                                                                mountIcon: (el) => {
                                                                                    // Custom logic to mount an icon
                                                                                    ReactDOM.render(<IdentificationCard size={16} weight="fill" />, el);
                                                                                },
                                                                                unmountIcon: (el) => {
                                                                                    // Custom logic to unmount an icon
                                                                                    // if (el) el.innerHTML = '';
                                                                                    // el.innerHTML = IdentificationBadge
                                                                                    ReactDOM.render(<IdentificationCard size={16} />, el);
                                                                                },
                                                                                mount: (el) => {
                                                                                    // Custom logic to mount the page
                                                                                    ReactDOM.render(
                                                                                        <Provider store={applicationStore}>
                                                                                            {/* <SavedDataPrompt /> */}
                                                                                            <OnboardingV2 user={user} getToken={getToken} sidenav={true} moveNext={() => { toast.success("Profile Updated"); window.location.reload() }} />
                                                                                        </Provider>
                                                                                        , el);
                                                                                    // el.innerHTML = '<div>Custom Page Content</div>';
                                                                                },
                                                                                unmount: (el) => {
                                                                                    // Custom logic to unmount the page
                                                                                    if (el) el.innerHTML = '';
                                                                                },
                                                                            },
                                                                        ]
                                                                    }
                                                                );
                                                            } else {
                                                                navigate("/my-referral");
                                                            }
                                                        }} className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer">
                                                            <p
                                                                type="text"
                                                                className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"

                                                                id="navigation-account"
                                                            >
                                                                {item.label}
                                                            </p></li >
                                                    ) : (
                                                        <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer" onClick={() => { window.analytics.reset(); clearTokens() }}>
                                                            <SignOutButton redirectUrl={process.env.REACT_APP_POST_SIGNOUT_URL} />
                                                        </li>
                                                    )}
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                trigger={["click"]}
                                className="hover:cursor-pointer hover:text-[#5e59ff]"
                            >
                                <Space className="flex w-full">
                                    <div className="flex h-[32px] w-[32px] flex-shrink-0 rounded-[4px] overflow-hidden">
                                        <img
                                            src={user?.imageUrl}
                                            alt="profile"
                                            className="flex-shrink-0"
                                            style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div className="inner">
                                        <p className="text-[12px]">{user?.firstName}</p>
                                    </div>
                                </Space>
                            </Dropdown>
                            </li>
                            <li className={`flex ${sidenavCollapsed ? 'mx-auto' : '!justify-end px-[24px]'}`} onClick={changeSidenavCollapse}>
                                <button className="border flex shadow h-[32px] w-[32px] rounded">
                                    {sidenavCollapsed ?
                                        <ArrowLineRight size={16} className="text-[#141414]  mx-auto my-auto" /> :
                                        <ArrowLineLeft size={16} className="text-[#141414]  mx-auto my-auto" />
                                    }
                                </button>
                            </li>
                        </ul>
                    </div>
                </div >
            </div >
            <ToastHandler />
            <ChatHistory />
            <DraftDocumentQuestionaireModal
                email={email}
                ref={draftDocumentModalRef} />


            {showLawyerCard && <LawyerCard />}
            {checkoutUrl && !showUpgradingPlan && < PaymentModal />}

            {/* Popups  */}
            {(showSubscriptionPopup || showUpgradingPlan || showBusinessPlanUnlocked || showFindBestLawyer || showMeetingConfirmation || showNextSteps || showScheduleMeeting) &&
                <UpSellFlowPopup />
            }

            {
                showReviewRequestedModal && (
                    <ReviewRequested />
                )
            }
            {showLegalReviewRequestStatus &&
                <LawyerReviewRequested />
            }

            {showLegalReviewCompletedModal &&
                <LegalReviewComplete />
            }

            {showPersonalPlanThreadsExhausted &&
                <PersonalAccountThreadsExhausted />}

            {
                (showLawyerAssignedModal || showOngoingReviewModal || showReviewCompleteModal) && <LegalReviewModals />
            }

            <PlanSelectionPopup isOpen={userMetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE && showUpgradePlanNudge} />

            {smallScreenModalOpen && <SmallScreenModalComponent />}
            {/* <WSInputBox /> */}
            {/* <WebsocketRefreshModal /> */}
        </>
    )
}

export default SideNav;