import React from "react";
import BaseLayout from "../layouts/BaseLayout";
import LawyerReviews from "./LawyerReviews";
import PlanSelectionPopup from './PlanSelectionPopup';
import WelcomeBanner from './WelcomeBanner'
import UpgradePlanNudge from './UpgradePlanNudge'
import SpecialisedLawyer from "./SpecialisedLawyer";
import UpSellFlowPopup from "./UpsellFlowPopup";
import { useSelector } from "react-redux";
import LawyerInfo from "./LawyerInfo";
import LawyerCard from "./LawyerCard";
import ReviewRequested from "./ReviewRequested";
import ReviewComplete from "./ReviewComplete";
import LawyerReviewRequested from "./LawyerReviewRequestedProgress";
import ReliabilityGuaranteedPopup from "./ReliabilityGuaranteedPopup";
import { PLANS } from "src/components/Constants";

const LawyerHub = () => {

  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
  const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);

  var data = {
    "lawyer_hub_banner": <WelcomeBanner />,
  }
  return (
    <BaseLayout>
      <div className="flex flex-row w-full h-screen gap-10 py-10 overflow-y-scroll px-14 ">

        {/* Left Part */}
        <div className="w-full pb-10">
          <h1 className="pb-2 mb-6 text-2xl font-bold border-b">
            Lawyer Hub
          </h1>
          {!userMetadata?.lawyer_info &&
            <>
              <WelcomeBanner />
              {!userMetadata?.lawyer_matching_meeting_at &&
                <SpecialisedLawyer />
              }
            </>
          }

          {(userMetadata?.subscription_type === PLANS.BUSINESS_TRIAL || userMetadata.subscription_type === PLANS.BUSINESS_TRIAL_ENDED) &&
            <UpgradePlanNudge />
          }
          {lawyerHubData && lawyerHubData.length > 0 && (
            <LawyerReviews lawyerHubData={lawyerHubData} />
          )}


          {/* <LawyerCard /> */}

          {/* <ReviewRequested /> */}

          {/* <ReviewComplete /> */}


          {/* <ReliabilityGuaranteedPopup /> */}


        </div>

        {/* Right Part */}
        {(userMetadata?.lawyer_info || userMetadata?.lawyer_matching_meeting_at) && (
          <div className="h-fit min-h-full rounded-xl shadow-sm min-w-[400px] border">
            <LawyerInfo userMetadata={userMetadata} />
          </div>
        )}
      </div>

    </BaseLayout>);
};

export default LawyerHub;
