import businessImg from "../../images/business.svg";
import personalImg from "../../images/personal.svg";

const OnboardingStep1 = ({ isEntity, setIsEntity, setNotice }) => {
  const chooseType = (type) => {
    if (type === "business") {
      setIsEntity(true);
      setNotice("");
    } else {
      setIsEntity(false);
      setNotice("");
    }
  };
  return (
    <div className="flex flex-col gap-[20px] justify-center items-center py-4">
      <div
        onClick={() => chooseType("business")}
        className={`border-2 px-[24px] w-full justify-start py-[12px] md:py-[32px] flex flex-row gap-[12px] rounded-md cursor-pointer ${isEntity ? "border-black" : ""} `}
        tabIndex={0}
      >
        <div className=" flex justify-center items-center select-none">
          <img className="h-[65px]" src={businessImg} alt="Business" />
        </div>
        <div className=" flex flex-col w-full justify-center">
          <p className="font-semibold text-xl">Business</p>
          <p className="hidden lg:block w-[80%] font-normal text-md text-[#444444]">
            I need help running a business: <br />
            <span className="text-black font-medium">contracts, compliance, employment, litigation, etc.</span>
          </p>
          <p className="block lg:hidden w-[100%]  font-normal text-md text-[#444444]">
            I need help running a business:
            <span className="text-black font-medium">contracts, compliance, employment, litigation, etc.</span>
          </p>
        </div>
      </div>

      <div
        onClick={() => chooseType("personal")}
        className={`border-2 px-[24px] w-full justify-start py-[12px] md:py-[32px] flex flex-row gap-[12px] rounded-md cursor-pointer ${!isEntity ? "border-black" : ""} `}
        tabIndex={0}
      >
        <div className="flex justify-center items-center">
          <img className="h-[56px]" src={personalImg} alt="Personal" />
        </div>
        <div className="flex flex-col w-full justify-center select-none">
          <p className="font-semibold text-xl font-sans">Personal</p>
          <p className="hidden lg:block w-[80%] font-normal text-md text-[#444444]">
            I need help on personal legal maters:  <br />
            <span className="text-black font-medium">
              real estate, wills, consumer, accidents, criminal, etc.
            </span>
          </p>
          <p className="block lg:hidden w-[90%] font-normal text-md text-[#444444]">
            I need help on personal legal maters: {" "}
            <span className="text-black font-medium">
              real estate, wills, consumer, accidents, criminal, etc.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep1;
