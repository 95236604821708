import { X } from '@phosphor-icons/react/dist/ssr'
import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useDispatch, useSelector } from 'react-redux'
import { PLANS } from 'src/components/Constants'
import { closePaymentModal } from 'src/pages/chatpage/chatSlice'
import { resetRequestLegalReviewFailureReason } from 'src/pages/onboarding/onboardingSlice'
import { fetchMeetingDetails, setShowNextSteps, setShowScheduleMeeting } from 'src/pages/onboarding/onboardingSlice'


const ScheduleMeeting = () => {
    const dispatch = useDispatch()
    const [meetingScheduled, setMeetingScheduled] = React.useState(false)
    const [meetingDetails, setMeetingDetails] = React.useState(null)
    const [calendlyLink, setCalendlyLink] = React.useState(process.env.REACT_APP_CALENDLY_MEETING_ID)

    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const threadData = useSelector(state => state.messageSlice.threadData);
    const lawyerCalendlyLink = useSelector((state) => state.onboarding.lawyerCalendlyLink)

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { setMeetingScheduled(true); setMeetingDetails(e.data.payload) },
        onPageHeightResize: (e) => console.log(e.data.payload.height),
    });

    const closeModal = () => {
        dispatch(setShowScheduleMeeting({ "show": false, "lawyer_details": {} }))
    }

    const submitLawyerMatchingRequest = () => {
        var meetingId = meetingDetails.event.uri.split('/').pop()
        if (meetingId) {
            dispatch(fetchMeetingDetails({ "eventId": meetingId, "threadId": threadData?.id }))
            dispatch(setShowNextSteps(true));
            dispatch(setShowScheduleMeeting({ "show": false, "lawyer_details": {} }))
        }
    }

    useEffect(() => {
        if (lawyerCalendlyLink) {
            setCalendlyLink(lawyerCalendlyLink)
        }
        else {
            setCalendlyLink(process.env.REACT_APP_CALENDLY_MEETING_ID)
        }
    }, [lawyerCalendlyLink]) // eslint-disable-link
    return (
        <>
            <div className='flex flex-col h-full p-0'>
                <X className='absolute top-4 right-4 cursor-pointer' size={20} onClick={() => { dispatch(setShowScheduleMeeting({ "show": false, "lawyer_details": {} })); dispatch(resetRequestLegalReviewFailureReason()) }} />
                <InlineWidget url={calendlyLink} styles={{ height: "680px", width: "1000px" }} />
                {meetingDetails && (
                    <>
                        {(userMetadata.subscription_type !== PLANS.PERSONAL_FREE || threadData?.id) ?
                            <div className='flex justify-end px-[24px] '>
                                <button className='bg-[#18475A] text-white px-4 py-2 rounded-lg my-4' onClick={() => { submitLawyerMatchingRequest() }}>Next</button>
                            </div>
                            :
                            <div className='flex justify-end px-[24px] '>
                                <button className='bg-[#18475A] text-white px-4 py-2 rounded-lg my-4' onClick={() => { closeModal() }}>Close</button>
                            </div>
                        }
                    </>
                )}
            </div >
        </>
    )
}

export default ScheduleMeeting