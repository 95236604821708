import React from 'react';
import SignedDocumentImage from "src/images/signeddocument.png"

const LegalReviewCompletePersonal = () => {
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-full'>
            <img src={SignedDocumentImage} alt="Expert Lawyer Assigned" className="w-[160px] mx-auto" />
            <h1 className="text-2xl font-[600] text-[#353535]">Lawyer review completed!</h1>
            <p className="text-base font-[400] text-center text-[#353535]">The specialized lawyer assigned to this thread has completed their review. <br />
                Please check your email for the details</p>
        </div>
    );
};

export default LegalReviewCompletePersonal;