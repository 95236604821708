import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    homepageSuggestions, askHomepageSuggestions, documentTypeSuggestions,
    threads,
    savedPartiesForDraft
} from './utilities';
import axios from 'axios';
import { ErrorLogging } from 'src/components/DocumentViewer/helpers';

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;


export const getSavedParties = createAsyncThunk("homeslice/getSavedParties", async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken2");
    const headers = { Authorization: `Bearer ${accessToken}` };
    try {
        const url = `${BASE_ENDPOINT}/api/user/get-saved-parties`;
        const response = await axios.get(url, { headers: headers });
        return {
            data: data,
            response: response.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error, data: data });
    }
});


export const getAllDraftTypes = createAsyncThunk("homeslice/getAllDraftTypes", async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken2");
    const headers = { Authorization: `Bearer ${accessToken}` };
    try {
        const url = `${BASE_ENDPOINT}/api/user/get-draft-types`;
        const response = await axios.get(url, { headers: headers });
        return {
            data: data,
            response: response.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error, data: data });
    }
});


export const getEducationMetadata = createAsyncThunk("homeslice/getEducationMetadata", async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken2");
    const headers = { Authorization: `Bearer ${accessToken}` };
    try {
        const url = `${BASE_ENDPOINT}/api/user/get-education-metadata`;
        const response = await axios.get(url, { headers: headers });
        return {
            data: data,
            response: response.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error, data: data });
    }
});

export const createNewParty = createAsyncThunk("homeslice/createNewParty", async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken2");
    const headers = { Authorization: `Bearer ${accessToken}` };

    var url;
    let isEdit = false
    let editId = ''
    var method = axios.post
    if (data.id) {
        url = `${BASE_ENDPOINT}/api/user/update-party/${data.id}`;
        method = axios.put
        isEdit = true
        editId = data.id
    }
    else {
        url = `${BASE_ENDPOINT}/api/user/add-party`;
    }
    delete data.has_entity;
    delete data.id;

    try {
        const response = await method(url, data, { headers: headers, });
        return {
            data: data,
            response: response.data,
            isEdit,
            editId
        };
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error, data: data });
    }
});



export const onboardUser = createAsyncThunk("homeslice/onboardUser", async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken2");
    const headers = { Authorization: `Bearer ${accessToken}` };

    var url = `${BASE_ENDPOINT}/api/user/onboard`;

    try {
        const response = await axios.post(url, data, { headers: headers, });
        return {
            data: data,
            response: response.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error, data: data });
    }
});


const homeSlice = createSlice({
    name: 'homeslice',
    initialState: {
        // Define your initial state here
        suggestionsHomepage: homepageSuggestions,
        allSuggestions: {},
        askhomeSuggestions: askHomepageSuggestions,
        documentTypeSuggestions: documentTypeSuggestions,
        partiesList: [],
        selectedParties: {},
        selectedDocumentType: {},
        threads: threads,
        updatingParty: false,
        viewChatHistory: false,
        showWsInputBox: false,
        savedPartiesForDraft: savedPartiesForDraft,
        email: null,
        savedParties: [],
        savedPartiesFetchStatus: "idle",
        sidenavCollapsed: false,
        draftTypesStatus: "idle",
        draftTypes: [],
        toast: {},
        onboardUserStatus: "idle",
        onboardFailureReason: "",

        educationMetadataFetchStatus: "idle",
        smallScreenModalOpen: false,
        createNewPartyStatus: "idle"
    },
    reducers: {
        // Define your reducers here
        addToast: (state, action) => {
            state.toast = { ...action.payload, id: Math.random() };
        },
        setSelectedDocumentType: (state, action) => {
            state.selectedDocumentType = action.payload;
        },
        setSelectedParties: (state, action) => {
            state.selectedParties = action.payload;
        },
        resetCreateNewPartyStatus: (state) => {
            state.createNewPartyStatus = "idle";
        },
        setViewChatHistory: (state, action) => {
            if (action.payload === null || action.payload === undefined) {
                state.viewChatHistory = !state.viewChatHistory;
            }
            else {
                state.viewChatHistory = action.payload;
            }
        },
        setWsInputBox: (state, action) => {
            state.showWsInputBox = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            localStorage.setItem("email", action.payload);
        },
        updateSidenavCollapse: (state, action) => {
            state.sidenavCollapsed = action.payload;
        },
        setSmallScreenModalOpen: (state, action) => {
            state.smallScreenModalOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSavedParties.pending, (state) => {
                state.savedPartiesFetchStatus = "loading";
                state.partiesList = [];
            })
            .addCase(getSavedParties.fulfilled, (state, action) => {
                state.partiesList = action.payload.response;
                state.savedPartiesFetchStatus = "success";
            })
            .addCase(getSavedParties.rejected, (state, action) => {
                state.partiesList = [];
                state.savedPartiesFetchStatus = "failed";
                ErrorLogging(action?.payload?.error);
            })
            // get all draft types
            .addCase(getAllDraftTypes.pending, (state) => {
                state.draftTypesStatus = "loading";
                state.draftTypes = [];
            })
            .addCase(getAllDraftTypes.fulfilled, (state, action) => {
                state.draftTypes = action.payload.response;
                state.draftTypesStatus = "success";
            })
            .addCase(getAllDraftTypes.rejected, (state, action) => {
                state.draftTypes = [];
                state.draftTypesStatus = "failed";
                ErrorLogging(action?.payload?.error);

            })
            // get education metadata
            .addCase(getEducationMetadata.pending, (state) => {
                state.educationMetadataFetchStatus = "loading";
                state.allSuggestions = [];
            })
            .addCase(getEducationMetadata.fulfilled, (state, action) => {
                state.allSuggestions = action.payload.response;
                state.educationMetadataFetchStatus = "success";
            })
            .addCase(getEducationMetadata.rejected, (state, action) => {
                state.allSuggestions = [];
                state.educationMetadataFetchStatus = "failed";
                ErrorLogging(action?.payload?.error);
            })

            // get createNewParty
            .addCase(createNewParty.pending, (state) => {
                state.createNewPartyStatus = "loading";
                state.updatingParty = false;
            })
            .addCase(createNewParty.fulfilled, (state, action) => {
                var response = action.payload.data;
                response = { ...response, id: action.payload.response?.party_id };
                state.partiesList = [response, ...state.partiesList]
                state.selectedParties = response
                state.createNewPartyStatus = "success";
                if (action.payload.editId) {
                    state.updatingParty = true;
                }
            })
            .addCase(createNewParty.rejected, (state, action) => {
                state.createNewPartyStatus = "failed";
                ErrorLogging(action?.payload?.error);
            })

            // onboard user
            .addCase(onboardUser.pending, (state) => {
                state.onboardUserStatus = "loading";
                state.updatingParty = false;
            })
            .addCase(onboardUser.fulfilled, (state, action) => {
                var response = action.payload.data;
                response = { ...response, id: action.payload.response?.party_id };
                state.partiesList = [...state.partiesList, response]
                state.selectedParties = response
                state.onboardUserStatus = "success";
                if (action.payload.data.id) {
                    state.updatingParty = true;
                }
            })
            .addCase(onboardUser.rejected, (state, action) => {
                state.onboardUserStatus = "failed";
                state.onboardFailureReason = action.payload?.error?.response?.data?.detail;
                ErrorLogging(action?.payload?.error);
            })


    }
});

export default homeSlice.reducer;
export const { setViewChatHistory, setWsInputBox, setSelectedParties, setEmail, setSelectedDocumentType, updateSidenavCollapse, addToast, resetCreateNewPartyStatus, setSmallScreenModalOpen } = homeSlice.actions;
