import React, { createContext, useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, markQuestionAnswered, setAskToDraftIntentWSResponse, setAwaitingDraftQuestionsResponse, setAwaitingResponse, setChatInputMessage, setDocument, setDocumentChangeAction, setMessagingDisabled, setQuestions, setRegenerationHappening, setRetryWS, setRetryWSState, setScroll, setSubmittingQnA, setUpdatingQuestion, setupNewThread, skipFollowupQuestions } from './pages/chatpage/messageSlice';
import { useAuth } from '@clerk/clerk-react';
import { insertNewThread, updateThreadTitleLocal } from './pages/chatpage/threadsSlice';
import applicationStore from "./persistence/stores/RootStore.js"

import { toast } from 'react-toastify';
import { decreaseThreadsRemaining } from './pages/onboarding/onboardingSlice';
import { PLANS } from './components/Constants';
const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_WS_BASE_ENDPOINT;
    var usermetadata = useSelector((state) => state.onboarding.userMetadata);
    const [isConnected, setIsConnected] = useState(false);
    const [accesstoken, setAccesstoken] = useState(null);
    const { getToken } = useAuth();
    const socketRef = useRef(null);
    const retryIntervalRef = useRef(null);
    const [retryCount, setRetryCount] = useState(0);

    const randomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const TAB_ID = "key-" + randomString(15);

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (socketRef.current) socketRef.current.close();
            if (retryIntervalRef.current) clearTimeout(retryIntervalRef.current);
        };
    }, [url, accesstoken, retryCount]);

    useEffect(() => {
        connectWebSocket()
    }, [usermetadata])

    useEffect(() => {
        getTokenValue()

    }, [getToken])

    useEffect(() => {
        const handleTabClose = () => {
            if (localStorage.getItem(TAB_ID)) {
                localStorage.removeItem(TAB_ID);
            }
            if (socketRef.current) {
                socketRef.current.close();
            }
        };

        window.addEventListener("beforeunload", handleTabClose);
        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const getMyToken = async () => {
        const token = await getToken({ template: "Backend" });
        return token;
    }
    const getTokenValue = async () => {
        const token = await getMyToken();
        if (token && typeof token === "string") {
            setAccesstoken(token);
        }
    }

    const connectWebSocket = () => {
        if (!accesstoken) {
            return;
        }
        if (!usermetadata?.subscription_type) {
            return
        }

        const existingTab = localStorage.getItem(TAB_ID);
        if (existingTab) {
            console.log("Another tab is already handling the WebSocket connection.");
            return;
        }
        if (socketRef.current && (socketRef.current.readyState === WebSocket.OPEN || socketRef.current.readyState === WebSocket.CONNECTING)) {
            return;
        }

        localStorage.setItem(TAB_ID, randomString(5));


        const socket = new WebSocket(`${url}/ws?token=${accesstoken}`);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsConnected(true);
            dispatch(setRetryWSState("idle"))
            dispatch(setRetryWS(false))
            setRetryCount(0);
            // alert(` connected!`);
        };

        socket.onclose = () => {
            dispatch(setRetryWSState("idle"))
            dispatch(setRetryWS(true))
            setIsConnected(false);
            // alert(` discconnected!`);

            if (localStorage.getItem(TAB_ID)) {
                localStorage.removeItem(TAB_ID);
            }

            retryIntervalRef.current = setTimeout(() => {
                if (!socketRef.current || (socketRef.current.readyState === WebSocket.CLOSED || socketRef.current.readyState === WebSocket.CLOSING)) {
                    setRetryCount((count) => count + 10);
                    connectWebSocket();
                }
            }, 10000);
        };
        socket.onmessage = (event) => {
            var message = event.data;
            // setMessages((prev) => [...prev, message]);
            // console.log(event);
            var usermetadata = applicationStore.getState().onboarding.userMetadata;

            if (event.data === "Connection successful!") { return }
            try {
                message = JSON.parse(event.data);
            }
            catch (e) {
                console.log("Error parsing JSON: ", e);
                if ((event.data.toLowerCase().search("failed") > -1)) {
                    getTokenValue()
                }
                return;
            }
            if (message.payload?.error || message?.error || message?.status_code > 299) {
                // dispatch(addToast({ type: "error", message: message?.payload?.error || message?.error }))
                // toast.error(message?.payload?.error || message?.error)
                var errorMessage = "";
                switch (message.action) {
                    case "ask/thread-not-created":
                    case "draft/thread-not-created":
                        if (message.payload?.error?.search("threads limit reached") > -1) {
                            errorMessage = message.payload.error
                        }
                        errorMessage = `Error creating thread. ${errorMessage || ""}`;
                        break;
                    case "ask/create-thread":
                    case "draft/create-thread":
                        errorMessage = "Error creating thread";
                        break;
                    case "ask/add-message":
                    case "draft/add-message":
                        errorMessage = "Error adding message";
                        // TODO : regenerate the question
                        break;
                    case "ask/new-message-available":
                    case "draft/new-message-available":
                        errorMessage = "Failed to generate AI response. Please try again";
                        break;
                    case "draft/submit-and-create-document":
                    case "draft/create-document":
                        errorMessage = "Failed to create document. Please try again";
                        // TODO : regenerate the question
                        break;
                    case "draft/update-question":
                        errorMessage = "Error updating question";
                        // TODO : regenerate the question
                        break;
                    case "draft/inferred-metadata":
                        errorMessage = "Error inferring metadata";
                        // dispatch(setAskToDraftIntentWSResponse({
                        //     "document_metadata_id": "recMlg3kj5bGSPL5W",
                        //     "new_document_name": "Ask to draft bylawys document",
                        //     "instruction_summary": "some newly created summary based on ask to draft intent"
                        // }))

                        break;
                    // TODO : handle the cases when user action was successful, but the backend failed. like sending new message fomr hte backend

                    default:
                        errorMessage = ""
                        break;
                }
                if (errorMessage) {
                    toast.error(errorMessage)
                }
                if (message?.payload?.allow_regeneration) {
                    dispatch(addMessage({ "new_messages": [{ "message_type": "regenerate_ai_response" }] }))
                }
                dispatch(setAwaitingResponse(false));
                dispatch(setMessagingDisabled(false));
                return;
            }
            var scroll = applicationStore.getState().messageSlice.scroll;
            var chatInputMessage = applicationStore.getState().messageSlice.chatInputMessage;

            switch (message.action) {
                case "ai_title_available":
                    dispatch(updateThreadTitleLocal(message.payload))
                    break;

                case "ask/thread-created":
                    // console.log(window.location.pathname)
                    if (window.location.pathname === "/ask") {
                        dispatch(setupNewThread({ threadId: message.payload.thread_id, threadType: "ask", messageId: message.payload.message_id }));
                        if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                            dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                        }
                        dispatch(insertNewThread({
                            "thread_id": message.payload.thread_id, "thread_type": "ask",
                            "messaging_disabled": message.payload.messaging_disabled,
                            "id": message.payload.thread_id
                        }));
                    }
                    if (usermetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
                        dispatch(decreaseThreadsRemaining())
                    }
                    // console.log("Thread created: ", event.data);
                    break;
                case "ask/thread-deleted":
                    // console.log("Thread deleted: ", event.data);
                    break;
                case "ask/message-added":
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    if (threadData && threadData.id === payload.thread_id) {
                        dispatch(addMessage(payload))
                        dispatch(setChatInputMessage(""));
                    }
                    dispatch(setAwaitingResponse(true));
                    if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                        dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                    }
                    break;
                case "ask/new-message-available":
                    var payload = message.payload;
                    payload["source"] = "ai";
                    payload["is_user_message"] = false;
                    payload["text"] = payload["message_text"]
                    payload["id"] = payload["message_id"]
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    if (threadData && threadData.id === payload.thread_id) {
                        dispatch(addMessage(payload))
                    }
                    dispatch(setAwaitingResponse(false));
                    if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                        dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                    }
                    if (message.payload?.information_messages) {
                        message.payload.information_messages.forEach(element => {
                            dispatch(addMessage({
                                "message_text": element,
                                "message_type": "information_message"
                            }))
                        })
                    }
                    break;
                case "ask/regeneration-not-allowed":
                    toast.error("Regeneration not allowed. Please try again.")
                    break;
                case "draft/inferred-metadata":
                    // console.log("Inferred metadata: ", event.data);
                    dispatch(setAskToDraftIntentWSResponse(message.payload));
                    dispatch(setAwaitingResponse(false));
                    break;
                case "draft/thread-created":
                    var selectedDocumentType = applicationStore.getState().homeslice.selectedDocumentType
                    var draftMessage = `Draft a ${selectedDocumentType.name}.
${selectedDocumentType.description}`
                    dispatch(setupNewThread({ threadId: message.payload.thread_id, threadType: "draft", messageId: message.payload.message_id, "message": draftMessage }));
                    if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                        dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                    }

                    dispatch(insertNewThread({
                        "thread_id": message.payload.thread_id, "thread_type": "draft",
                        "messaging_disabled": message.payload.messaging_disabled,
                        "id": message.payload.thread_id
                    }));
                    if (usermetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
                        dispatch(decreaseThreadsRemaining())
                    }
                    break;
                case "draft/set-questions":
                    var payload = message.payload
                    var activeThreadData = applicationStore.getState().messageSlice.threadData;
                    if (activeThreadData && activeThreadData.id === payload.thread_id) {
                        dispatch(addMessage(payload))
                        dispatch(setQuestions(payload.questions))
                    }
                    if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                        dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                    }
                    dispatch(setAwaitingResponse(false));
                    dispatch(setRegenerationHappening(false));
                    break;
                case "draft/message-added":
                    // console.log("Message added: ", event.data);
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    // var chatInputMessage = applicationStore.getState().messageSlice.chatInputMessage;
                    if (threadData && threadData.id === payload.thread_id) {
                        payload["source"] = "user";
                        payload["is_user_message"] = true;
                        payload["message_text"] = chatInputMessage;
                        payload["id"] = payload["message_id"]
                        dispatch(addMessage(payload))
                        dispatch(setChatInputMessage(""));
                    }
                    if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                        dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                    }
                    dispatch(setAwaitingResponse(true));
                    break;
                case "draft/new-message-available":
                    // console.log("New message available: ", event.data);
                    var payload = message.payload;
                    payload["source"] = "ai";
                    payload["is_user_message"] = false;
                    payload["text"] = payload["message_text"]
                    payload["id"] = payload["message_id"]
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    // var documentChangeAction = applicationStore.getState().messageSlice.documentChangeAction;
                    // if (documentChangeAction) {
                    //     dispatch(addMessage({
                    //         ""
                    //     }))
                    //     dispatch(setDocumentChangeAction(null));
                    // }
                    if (threadData && threadData.id === payload.thread_id) {
                        dispatch(addMessage(payload))
                    }
                    if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
                        dispatch(setMessagingDisabled(message.payload.messaging_disabled));
                    }
                    else if (message.payload.messaging_disabled === false) {
                        dispatch(setMessagingDisabled(false));
                    }
                    if (message.payload?.information_messages) {
                        message.payload.information_messages.forEach(element => {
                            dispatch(addMessage({
                                "message_text": element,
                                "message_type": "information_message"
                            }))
                        })
                    }
                    dispatch(setAwaitingResponse(false));
                    dispatch(setSubmittingQnA(false));
                    dispatch(setRegenerationHappening(false));
                    break;
                case "draft/question-updated":
                    // console.log("question updated: ", event.data);
                    var updatingQuestion = applicationStore.getState().messageSlice.updatingQuestion;
                    if (updatingQuestion.id) {
                        if (updatingQuestion.action === "skip") {
                            dispatch(skipFollowupQuestions(updatingQuestion.activeIndex))
                        }
                        else if (updatingQuestion.action === "answer") {
                            dispatch(markQuestionAnswered({ index: updatingQuestion.activeIndex, response: updatingQuestion.response }))
                        }
                        // dispatch(setAwaitingDraftQuestionsResponse(false))
                        dispatch(setUpdatingQuestion({}));
                        dispatch(setAwaitingResponse(false));
                        dispatch(setAwaitingDraftQuestionsResponse(false))
                        dispatch(setMessagingDisabled(false));
                        dispatch(setSubmittingQnA(false));
                    }
                    break;
                case "draft/document-created":
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    if (threadData && threadData.id === payload.thread_id) {
                        dispatch(setDocument(payload))
                        dispatch(addMessage(payload))
                    }
                    dispatch(setSubmittingQnA(false));
                    dispatch(setAwaitingResponse(false));
                    dispatch(setMessagingDisabled(false));
                    dispatch(setRegenerationHappening(false));
                    break;
                case "draft/regeneration-not-allowed":
                    toast.error("Regeneration not allowed. Please try again.")
                    dispatch(setRegenerationHappening(false));
                    break;
                case "draft/party-updated":
                    dispatch(setAwaitingResponse(false));
                    dispatch(setMessagingDisabled(false));
                    break;
                case "draft/document-deleted":
                    // console.log("Document deleted: ", event.data);
                    break;
                case "ai-thread-title-available":
                    // console.log("AI thread title available: ", event.data);
                    break;
                default:
                    // console.log("Unknown message: ", event.data);
                    break;
            }
            if (scroll) {
                dispatch(setScroll(false))
            }
        };
        // Cleanup on component unmount
        return () => {
            // socket.close();
            if (socketRef.current) {
                socketRef.current.close();
            }
            if (localStorage.getItem(TAB_ID)) {
                localStorage.removeItem(TAB_ID);
            }
        };
    }


    // }, [url, accesstoken, retryCount, retryWS]);

    // Function to send a message through WebSocket
    const sendMessage = (message, scroll, draftQuestions) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify(message));
        }
        else {
            toast.error("Connection lost. Please refresh the page and try again.")
            dispatch(setAwaitingDraftQuestionsResponse(false));
            dispatch(setAwaitingResponse(false));
        }
        if (draftQuestions) {
            dispatch(setAwaitingDraftQuestionsResponse(true));
        }
        else {
            dispatch(setAwaitingResponse(true));
        }

        if (scroll) {
            dispatch(setScroll(true))
        }
    };

    const createMessage = (threadType, api, payload) => {
        let r = (Math.random() + 1).toString(36).substring(7);
        return {
            "action": `${threadType}/${api}`,
            "payload": payload,
            "trigger_ws_message_id": r
        };
    }

    // Handle incoming messages (optional)
    const [messages, setMessages] = useState([]);

    return (
        <WebSocketContext.Provider
            value={{
                isConnected,
                messages,
                sendMessage,
                createMessage
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
};

// Hook to use WebSocket context in any component
export const useWebSocketContext = () => useContext(WebSocketContext);
