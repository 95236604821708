import React, { useEffect } from 'react';
import { ArrowCounterClockwise, ArrowRight, ArrowsClockwise, ArrowUpRight, FastForward, Lightbulb, LockKey, PenNib, Spinner } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentChangeAction, setMessagingDisabled, setSavedDataInformation, setShowSavedDataModal } from '../../pages/chatpage/messageSlice';
import { trackAnalytics } from 'src/pages/helpers';
import Modal from 'antd/es/modal/Modal';
import mLogo from 'src/images/m.svg'
import RightArrowImage from "src/images/icons/arrow-right.svg";
import { Button } from 'antd';
import { useWebSocketContext } from 'src/WebsocketProvider';

const NewDraftTypePrompt = ({ threadType, chatInfo, email }) => {
    const dispatch = useDispatch();
    const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
    const metadata = useSelector((state) => state.onboarding.userMetadata);
    const threadData = useSelector(state => state.messageSlice.threadData);
    const messagingDisabled = useSelector((state) => state.messageSlice.threadData.messagingDisabled);

    const [index, setIndex] = React.useState(0);
    const [suggestedDraftType, setSuggestedDraftType] = React.useState({});
    const [originalDraftType, setOriginalDraftType] = React.useState({});
    const [showModal, setShowModal] = React.useState(false);

    const { sendMessage, createMessage } = useWebSocketContext();


    const getTheAlternateDraftType = (chatInfo) => {
        // debugger
        const draftType = draftTypeSuggestions.find((draftType) => draftType.id === chatInfo?.payload?.new_document_id);
        setSuggestedDraftType(draftType);
    }
    const getOriginalDraftType = (chatInfo) => {
        const draftType = draftTypeSuggestions.find((draftType) => draftType.id === chatInfo?.payload?.old_document_id);
        setOriginalDraftType(draftType);
    }
    useEffect(() => {
        getTheAlternateDraftType(chatInfo);
        getOriginalDraftType(chatInfo);
        setIndex(threadData.messages.indexOf(chatInfo));
    }, [])

    useEffect(() => {
        getTheAlternateDraftType(chatInfo);
        getOriginalDraftType(chatInfo);
    }, [chatInfo, draftTypeSuggestions])

    const handleViewData = () => {
        dispatch(setSavedDataInformation(chatInfo));
        // window.analytics.track("View draft Data clicked", { "user_email": email })
        trackAnalytics("View draft Data clicked", { "user_email": email });
        dispatch(setShowSavedDataModal(true));
    }

    const modalStyles = {
        header: {
            padding: 24,
        },
    };

    const takeAction = (actionType) => {
        // 2 action types: accept_document_change_suggestion, reject_document_change_suggestion
        trackAnalytics("Document change suggestion action taken", { "user_email": email, "action_type": actionType }, metadata);
        var message = createMessage(threadType, "add-message", { "thread_id": threadData?.id, "button_click_type": actionType });
        sendMessage(message, true)
        dispatch(setMessagingDisabled(true));
        dispatch(setDocumentChangeAction(actionType));
    }
    return (
        <div className="mt-[10px]">
            {!chatInfo?.payload?.user_submitted_button_click_type ?
                <>
                    {index === threadData.messages.length - 1 &&
                        <>
                            <div className={`flex flex-col bg-[#DCA21811] border-l-[2.2px] border-[#DCA218] ${threadType}-information-gathered p-[16px] rounded-[12px] gap-[12px]`}>
                                {suggestedDraftType && (
                                    <>
                                        <p className='flex gap-[4px] text-[16px] font-[600] text-[#353535] my-auto'><><Lightbulb size={20} className="text-[#DCA218] my-auto" /></>{suggestedDraftType.name}</p>
                                        <p className="text-[14px] font-[500] text-[#686868]">{suggestedDraftType.long_description}</p>
                                        <p className="flex my-auto text-[14px] font-[500] gap-[10px] cursor-pointer"><span onClick={() => { setShowModal(true) }} className="text-[14px] font-[700] text-[#011513]">LEARN MORE</span>
                                            <ArrowUpRight size={16} /></p>
                                    </>
                                )}

                            </div>

                            <div className="flex justify-end mt-[36px]">
                                {!messagingDisabled ?
                                    <div className="flex flex-row gap-[8px]">
                                        <button className="flex flex-row gap-[8px] py-[12px] h-[38px] font-[700] px-[24px] my-auto text-[#264653]"
                                            onClick={() => { takeAction("reject_document_change_suggestion") }}
                                        >
                                            <span className="flex my-auto text-[14px]">Continue with {originalDraftType?.name}</span>
                                            <FastForward size={20} className='text-[#264653] my-auto' />
                                        </button>
                                        <button className="my-auto h-[38px] leading-[19.6px] text-[14px] px-[12px] bg-[#264653] rounded-[8px] text-white"
                                            onClick={() => { takeAction("accept_document_change_suggestion") }}
                                        >
                                            Change to {suggestedDraftType?.name}
                                        </button>
                                    </div>
                                    : <Spinner size={16} className="animate-spin" />
                                }
                            </div>
                        </>
                    }
                </>
                :
                <div className="flex flex-row bg-[#ECF5F3CC] gap-[12px] rounded-[8px] px-[10px] py-[10px]">
                    {chatInfo?.payload?.user_submitted_button_click_type === "accept_document_change_suggestion" ?
                        <><span className="pt-1"><ArrowsClockwise size={16} className="text-[#248370]" /></span>
                            <p className="text-[16px] font-[500] leading-[25.6px] text-[#353535]">
                                {originalDraftType?.name} changed to <span className="text-[#18475A] font-[600]">{suggestedDraftType?.name}</span>
                            </p>
                        </> : <>
                            <span className="pt-1"><ArrowCounterClockwise size={16} className="text-[#248370]" /></span>
                            <p className="text-[16px] font-[500] leading-[25.6px] text-[#353535]">
                                Document Type retained as <span className="text-[#18475A] font-[600]">{originalDraftType?.name}</span>
                            </p>
                        </>}
                </div>
                // <p className="mt-[12px]"><b>{chatInfo?.payload?.user_submitted_button_click_type === "accept_document_change_suggestion" ? `change the draft to ${suggestedDraftType?.name}` : `continue with ${originalDraftType?.name}`}</b> </p>
            }
            {showModal && (
                <Modal
                    centered
                    open={showModal}
                    onOk={() => setShowModal(false)}
                    onCancel={() => setShowModal(false)}
                    className="image-upload-pop-up h-full rounded-[10px] bg-[#fff] overflow-hidden"
                    title={suggestedDraftType?.name}
                    styles={modalStyles}
                    closable={true}
                    footer={[
                        <div className='flex flex-row justify-end px-5 border-t py-3 my-auto'>
                            <Button
                                onClick={() => { setShowModal(false) }}
                                className="flex justify-between gap-[8px] py-[12px] h-[48px] px-[24px] my-auto bg-[#18475A] text-white"
                            >
                                <span >Ok, Got it</span>
                                <ArrowRight size={16} className="text-white my-auto" weight="bold" />
                            </Button>
                        </div>,
                    ]}
                >
                    <div className='flex flex-col'>
                        <div className='flex w-[960px] pt-[24px] pb-[32px] px-[40px] border-t'>
                            <div id="left" className='h-[400px] w-[60%]'>
                                <div className='flex flex-col h-48 gap-[18px] pr-20'>
                                    <p className='font-semibold text-[14px] font-sans text-[#141414]'>About {suggestedDraftType?.name}</p>
                                    <p className='font-normal text-[14px] font-sans text-[#353535]'>{suggestedDraftType?.long_description}</p>
                                </div>
                                {false &&
                                    // removing this section as requested by Aarshay on slack
                                    <div className='flex flex-col gap-2'>
                                        <p className='text-[#141414] text-[14px] font-semibold'>Common Usages</p>
                                        <div className='flex flex-wrap gap-2'>
                                            {suggestedDraftType?.common_use_cases?.map((name) => {
                                                return <span className='bg-[#F2F2F280] px-2 py-1 rounded-[98px] font-normal cursor-pointer'>{name}</span>
                                            })}
                                            {/* <span className='px-2 py-1 rounded-[98px] underline font-normal cursor-pointer'>+3 more</span> */}
                                        </div>
                                    </div>
                                }
                                <div className=''>
                                    <p className='font-medium text-[14px] font-sans mb-2'>Also known as</p>
                                    <p className="">{suggestedDraftType?.additional_search_terms?.slice(0, 1)?.map((name) => {
                                        return <span className='bg-[#F2F2F280] leading-[20px] w-full px-3 py-2 rounded-[98px] font-normal cursor-pointer'> {name} </span>
                                    })}</p>
                                </div>
                            </div>
                            <div id="Right" className='h-[400px] w-[40%] border-blue-500 flex flex-col gap-3 px-4'>
                                <div className='w-[326px] h-[355px] bg-[#FCFCFA] relative rounded-[10px] p-[20px] flex flex-col gap-[12px]'>
                                    <span><LockKey size={32} className='text-[#748A8C]' /> </span>
                                    <p className='font-normal italic text-[23.08px]'>{JSON.stringify(suggestedDraftType.name)}</p>
                                    <img src={mLogo} alt="m" className='absolute bottom-0' />
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal >
            )}
        </div >
    );
};

export default NewDraftTypePrompt;