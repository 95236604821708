import React from 'react';
import ToggleSwitch from '../components/ToggleSwitch';
import Button from '../Button';
import { ArrowUpRight } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
const RequestReviewFormAside = (props) => {
    const { email,
        trackAnalytics,
        setShowLearnMoreModal,
        dispatch,
        legalReiviewImg,
        text,
        setText,
        loading,
        isPhone,
        setIsPhone,
        phoneNumber,
        setPhoneNumber,
    } = props;

    const location = useLocation()
    const [isFieldInFocus, setIsFieldInFocus] = React.useState(false);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
    const threadData = useSelector(state => state.messageSlice.threadData);
    let legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);

    return (
        <section className="">
            <div id="Before-request">
                {location.pathname !== '/home' && userMetadata?.lawyer_info ? <></>
                    :
                    <div className="">
                        <div role="card" className='relative flex flex-col  py-12 border-[#18475A29] bg-[#F5FAF9]'>
                            {!userMetadata?.lawyer_info ? <div>
                                <img src={legalReiviewImg} alt="legalReviewImg" className='my-[24px]' />
                                <div role="left-section-text" className="w-full px-8 gap-[24px] pr-[50px]">
                                    {location.pathname === '/home' ? <h6 className="text-[#011513] text-[18px] font-semibold">Consult an expert lawyer— <span>{userMetadata?.entity_info ? "for" + userMetadata?.entity_info?.name : ""}</span> <br />Fast, Affordable,
                                        and Specific to your needs</h6> :
                                        <h6 className="text-[#011513] text-[18px] font-semibold leading-[28.8px]">Ask an Expert Lawyer — Fast and Affordable</h6>}

                                    {location.pathname !== '/home' ? <p className="text-[#353535] text-[16px] font-normal mt-2">Connect with a top-rated lawyer to review this chat and provide their guidance based on relevant experience</p>
                                        : <p className="text-[#353535] text-[16px] font-normal mt-2"> Connect with a top-rated lawyer for expert guidance tailored to your specific legal needs.</p>}
                                    <Button
                                        className={'mt-3'}
                                        secondary
                                        onClick={() => { trackAnalytics("learn more clicked from Request a Lawyer Review aside", { "user_email": email }); dispatch(setShowLearnMoreModal(true)) }}
                                        text={<>Learn more  {" "}<ArrowUpRight size={16} /></>}
                                    />
                                </div>
                            </div> :
                                <div className='px-[32px] flex flex-col gap-[24px]'>
                                    <div className='flex  items-center gap-[24px]'>
                                        <img src={userMetadata?.lawyer_info?.pre_signed_image_url}
                                            className="rounded-full my-auto border-[2px] w-[64px] h-[64px]"
                                            alt={userMetadata?.lawyer_info?.name} />
                                        <span className='flex flex-col'>
                                            <p className='font-semibold text-[16px] font-sans text-[#18475A]'>{userMetadata?.lawyer_info?.name}</p>
                                            <p className="font-medium text-[12px] text-[#949494]">Verified InHouse Counsel</p>
                                        </span>
                                    </div>
                                    <p className='font-semibold font-sans text-[18px] text-[#011513]'>Talk to your dedicated lawyer — <span>{userMetadata?.lawyer_info?.name}</span><br />
                                        for legal guidance on your specific needs</p>
                                </div>}
                        </div>

                        <div className='px-8 flex flex-col gap-[24px]'>
                            {/* <p className="mt-8 text-base font-semibold">Provide any context for that your lawyer should know <span className="text-[#18475A] text-sm font-normal ml-1">{`(optional)`}</span></p> */}
                            <div className='flex flex-col'>
                                <p className="text-[14px] font-medium font-sans text-[#353535] mt-4">Include any {window.location.pathname !== "/home" ? "additional" : ""} details {userMetadata?.lawyer_info ? userMetadata?.lawyer_info.name : "the lawyer"} should know. {window.location.pathname !== "/home" && <span className='text-[#686868]'>(optional)</span>} </p>

                                <textarea
                                    placeholder="Enter additional details here"
                                    className="border px-3 py-3 h-[90px] border-[#D1D1D1] w-full mt-2 rounded-lg"
                                    disabled={loading}
                                    value={text} onChange={(e) => setText(e.target.value)}
                                />
                            </div>
                            <div className='w-full relative'>
                                <div className='w-full rounded-lg h-[61px] border flex justify-between items-center px-[16px] py-[12px] shadow-sm'>
                                    <p className='font-sans text-[16px] font-semibold'>How do you want to be contacted?</p>
                                    <span className='flex justify-center items-center gap-[10px]'>
                                        <p className={`font-sans font-medium text-[14px]  ${isPhone ? 'text-[#686868]' : 'text-[#353535]'}`}>E-mail Me </p>
                                        <ToggleSwitch isOn={isPhone} setIsOn={setIsPhone} />
                                        <p className={`font-sans font-medium text-[14px] ${!isPhone ? 'text-[#686868]' : 'text-[#353535]'}`}>Call Me</p>
                                    </span>
                                </div>
                                <div className='w-full border p-[16px] bg-[#FAFAFA] flex flex-col gap-[8px] rounded-b-[12px] relative bottom-0'>
                                    {!isPhone ? <p className='font-medium text-[14px] font-sans text-[#353535]'>We will reach out on your email <span className='text-[#18475A]'>{email} </span> </p>
                                        : <div className='gap-[10px] flex flex-col'>
                                            <p className='font-sans font-medium text-[14px] text-[#353535]'>Mobile number</p>
                                            <div className='w-full h-[40px] border flex items-center rounded-[8px] bg-white'>
                                                <span className='px-[12px] text-[14px] font-normal text-sans text-[#686868] rounded-[8px]'>+1</span>
                                                <input type='text'
                                                    placeholder='Enter mobile number'
                                                    className='border-l px-[12px] h-full w-full text-[14px] font-normal font-sans rounded-r-[8px]'
                                                    value={phoneNumber}
                                                    onBlur={() => {
                                                        setIsFieldInFocus(false);
                                                    }}
                                                    onFocus={() => { setIsFieldInFocus(true) }}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                />
                                            </div>
                                            {
                                                phoneNumber && ((!isFieldInFocus && phoneNumber.length !== 10) || isNaN(phoneNumber)) &&
                                                <p className="text-danger">Please enter a valid Mobile Number</p>
                                            }
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </section>
    );
};

export default RequestReviewFormAside;