import { X } from '@phosphor-icons/react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSmallScreenModalOpen } from 'src/pages/Homepage/HomeSlice';

const SmallScreenModalComponent = () => {
    const dispatch = useDispatch();

    const smallScreenModalOpen = useSelector((state) => state.homeslice.smallScreenModalOpen);

    const closeModal = () => {
        dispatch(setSmallScreenModalOpen(false));
    }

    return (
        <Modal
            open={smallScreenModalOpen}
            className="rounded overflow-hidden "
            footer={null}
            onCancel={() => dispatch(setSmallScreenModalOpen(false))}
            cancelButtonProps={{
                style: { display: 'none' }
            }}
        >
            <div className="w-full flex flex-col  gap-[24px]">
                <div className="">
                    <div className="flex flex-col border-b gap-4 py-[20px] px-[16px]">
                        <div className='flex gap-[8px] w-full justify-between'>
                            <h1 className="text-[20px] font-[600] leading-[28px] font-[Lora] italic flex flex-row gap-[16px]">
                                Feature not available on mobile
                            </h1>
                            <X
                                size={24}
                                className="my-auto cursor-pointer"
                                color="#343330"
                                onClick={() => closeModal()}
                                id="cancel-draft-document-button"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row p-5">
                        <p>
                            This feature is only available on desktop. <br />
                            Please visit this page on a desktop to create draft.
                        </p>
                    </div>
                </div>
            </div>
        </Modal >
    );
};

export default SmallScreenModalComponent;