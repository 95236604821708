import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import LawyerAssigned from './LawyerAssigned';
import OngoingReview from './OngoingReview';
import LegalReviewCompletePersonal from './LegalReviewCompletePersonal';
import Button from '../Button';
import { setShowLawyerAssignedModal, setShowOngoingReviewModal, setShowReviewCompleteModal } from 'src/pages/onboarding/onboardingSlice';

const LegalReviewModals = () => {
    const dispatch = useDispatch()
    const showLawyerAssignedModal = useSelector((state) => state.onboarding.showLawyerAssignedModal);
    const showOngoingReviewModal = useSelector((state) => state.onboarding.showOngoingReviewModal);
    const showReviewCompleteModal = useSelector((state) => state.onboarding.showReviewCompleteModal);

    const closeModal = () => {
        if (showLawyerAssignedModal) {
            dispatch(setShowLawyerAssignedModal(false))
        }
        if (showOngoingReviewModal) {
            dispatch(setShowOngoingReviewModal(false))
        }
        if (showReviewCompleteModal) {
            dispatch(setShowReviewCompleteModal(false))
        }
    }
    return (
        <Modal
            open={showLawyerAssignedModal || showOngoingReviewModal || showReviewCompleteModal}
            className={`lawyer-assigned-modals overflow-x-hidden`}
            footer={null}
        >
            <div className="flex flex-col gap-[50px] pt-[56px] px-[40px]">
                {showLawyerAssignedModal && <LawyerAssigned />}
                {showOngoingReviewModal && <OngoingReview />}
                {showReviewCompleteModal && <LegalReviewCompletePersonal />}

                <div className="flex flex-row justify-between h-[96px] items-center align-middle py-[24px]">
                    <p className="text-[#686868] text-[14px] font-[500]">Need help? Contact us on <span className="text-[#18475A] font-[600]">support@inhouse.so</span></p>
                    <Button
                        onClick={() => { closeModal() }}
                        className={'py-3'}
                        text={'Ok, got it!'}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default LegalReviewModals