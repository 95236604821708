import { Briefcase } from '@phosphor-icons/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { legalReviewButtonClickedIntent, setShowRequestLegalReviewAside } from 'src/pages/onboarding/onboardingSlice';
import TalkToDedicatedLawyerButton from './TalkToDedicatedLawyerButton';
import { useSelector } from 'react-redux';
const RequestReviewButton = ({ threadData }) => {
const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const dispatch = useDispatch();
    return (<>
       {userMetadata?.lawyer_info ? <TalkToDedicatedLawyerButton userMetadata={userMetadata} /> :
         <button className="bg-[#18475A] border w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row"
            onClick={() => {
                dispatch(setShowRequestLegalReviewAside(true));
                dispatch(legalReviewButtonClickedIntent({ "threadId": threadData.id }))
            }
            }>
            <span className="my-auto"><Briefcase size={14} className="text-white my-auto" /></span>
            <span className="text-white my-auto text-[14px] leading-[19.6px]">Request Review</span>
        </button>}
        </>
    );
};

export default RequestReviewButton;