import { Briefcase } from '@phosphor-icons/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowLawyerCard } from 'src/pages/onboarding/onboardingSlice';
import { setShowRequestLegalReviewAside } from 'src/pages/onboarding/onboardingSlice';
const TalkToDedicatedLawyerButton = ({ userMetadata }) => {
    const dispatch = useDispatch();

    return (
        <button className="bg-[#18475A] border w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row"
            onClick={() =>dispatch(setShowRequestLegalReviewAside(true))}>
            <span className='my-auto'>
                <img src={userMetadata?.lawyer_info?.pre_signed_image_url} width="24" height="24"
                    className="rounded-full my-auto w-[24px] h-[24px] border-white border-[1px]"
                    alt={userMetadata?.lawyer_info?.name} />
            </span>
            <span className="text-white my-auto text-[14px] leading-[19.6px]">Consult {userMetadata?.lawyer_info?.name}</span>
            <span className="my-auto"><Briefcase size={16} className="text-white my-auto" /></span>
        </button>
    );
};

export default TalkToDedicatedLawyerButton;