import { Check, Sparkle, PencilSimpleLine, LightbulbFilament } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import FollowupInputPrompt from './FollowupInputPrompt';

const QnA = (props) => {
    const {
        index, activeIndex, setActiveIndex, message, submitAnswer, answers, setAnswers, awaitingResponse, questionsAnswered, questionsSkipped, isDocumentGenerated, threadsData, skipped,
        question, answeredIndexes, skipQuestion, indexOfMessage
    } = props

    const [isDisabled, setIsDisabled] = React.useState(false)
    const [isEditable, setIsEditable] = React.useState(true)
    const [inputValue, setInputValue] = React.useState(answers[index])
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [showActionButtons, setShowActionButtons] = React.useState(false)
    const [showInputPrompt, setShowInputPrompt] = React.useState(false)
    const [edit, setEdit] = useState(true)
    useEffect(() => {
        // messaging is disabled if :
        // 1. awaiting response
        // 2. question is already answered
        // 3. question is skipped
        // 4. document is generated
        // 5. the message is not the last message
        setIsDisabled(
            awaitingResponse || (
                !(questionsAnswered[index] === undefined || questionsAnswered[index] === null)
            ) || (
                questionsSkipped.indexOf(index) !== -1
            ) || isDocumentGenerated ||
            threadsData.isSharedThread ||
            (
                threadsData?.messages[threadsData?.messages.length - 1]?.message_type !== "draft_qna_message" && (
                    threadsData?.messages.length - 1 > indexOfMessage + 1 && (
                        threadsData?.messages[indexOfMessage + 1]?.message_type !== "legal_review_message"
                    )
                )
            )
        )
        setIsEditable(
            !(awaitingResponse || (
                !(questionsAnswered[index] === undefined || questionsAnswered[index] === null)
            ) || (questionsSkipped.indexOf(index) !== -1) ||
                isDocumentGenerated ||
                threadsData?.messages[threadsData?.messages.length - 1]?.message_type !== "draft_qna_message"
            )
        )
        // console.log("activeIndex", activeIndex, "index", index, "questionsAnswered", questionsAnswered, "skipped", skipped, "answeredIndexes", answeredIndexes, "isexpanded", activeIndex === index || Object.keys(questionsAnswered).indexOf(index) !== -1 || skipped.indexOf(index) !== -1)
        setIsExpanded(activeIndex === index || (Object.keys(questionsAnswered).indexOf(index.toString()) !== -1 || Object.keys(questionsAnswered).indexOf(index) !== -1) || skipped.indexOf(index) !== -1)
        setShowActionButtons(!(awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated) && !threadsData?.isSharedThread)
        setShowInputPrompt(
            // question is not skipped
            (skipped.indexOf(index) === -1)

        )
    }, [activeIndex, questionsAnswered, questionsSkipped, isDocumentGenerated, awaitingResponse, threadsData, skipped, answeredIndexes, index])


    const handelEdit = () => {
        setEdit(false)
        // adjustTextareaHeight();
    }

    return (
        <div>
            <div key={index} className={`flex border w-full bg-white
                                                 ${index === 0 ? "rounded-t-[12px]" : ""} 
                                                 ${index === message.questions?.length - 1 ? "rounded-b-[12px]" : ""}
                                                 ${activeIndex === index ? "!border-l-[#13866F] !border-l-4" : "border-collapse "}`}>
                <div className={`flex flex-col p-[16px] w-full gap-[20px]`}>
                    <div className="flex justify-between">
                        <p className="flex justify-start w-[90%] text-[14px] leading-[19.6px] font-[500] text-[#333333] gap-[8px]" >
                            <span className='my-auto flex items-center justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-[31.5px] border text-[#7F8C8D]'>
                                {Object.keys(questionsAnswered).indexOf(index) === -1 ? <>{index + 1} </> : <Check size={14} weight="bold" className="text-[#137513]" />}
                            </span>
                            <span className='w-full my-auto text-[16px] font-[500]'>{question?.text || question?.question}</span>
                        </p>
                        <p className="flex justify-center items-center my-auto w-[50px]">
                            {Object.keys(questionsAnswered).indexOf(index.toString()) !== -1 ? (
                                <PencilSimpleLine
                                    size={20}
                                    className="cursor-pointer text-[#949494]"
                                    onClick={handelEdit}
                                />
                            ) : skipped.indexOf(index) !== -1 ? (
                                <span className="bg-[#F2F2F2] text-[12px] font-medium font-sans text-[#686868] px-[4px] py-[2px] border rounded-[5px]">
                                    SKIPPED
                                </span>
                            ) : (
                                <svg
                                    className={`w-6 h-8 transition-transform duration-300 transform text-[#949494] ${index === activeIndex ? "rotate-180" : ""
                                        }`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => {
                                        if (activeIndex !== index) {
                                            setActiveIndex(index);
                                        } else {
                                            setActiveIndex(null);
                                        }
                                    }}>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            )}
                        </p>


                    </div>
                    {isExpanded && skipped.indexOf(index) === -1 && Object.keys(questionsAnswered).indexOf(index.toString()) === -1 && (
                        <div className='w-full flex mx-auto px-[12px] py-[8px] rounded-[6px] gap-[8px] items-start bg-[#fffaef]'>
                            <span className=""><LightbulbFilament size={16} className='text-[#DCA218] my-0' /></span>
                            <p className='font-normal text-[14px] font-sans text-[#353535]'>{question?.explanation}</p>
                        </div>)}
                    <>
                        {/* {(activeIndex >= index || Object.keys(questionsAnswered).indexOf(index) !== -1) && skipped.indexOf(index) === -1 && ( */}
                        {isExpanded && showInputPrompt && (
                            <div className="flex flex-col gap-[20px]">
                                {/* answers need to be taken care of. We will have to pass on something different here as input */}
                                {/* <p>{JSON.stringify(answeredIndexes)}</p> */}
                                <FollowupInputPrompt index={index} answers={answers}
                                    questionsAnswered={questionsAnswered}
                                    activeIndex={activeIndex}
                                    // disabled={awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated || threadsData.isSharedThread}
                                    disabled={isDisabled}
                                    question={question}
                                    submitAnswer={submitAnswer}
                                    setAnswers={setAnswers}
                                    skipQuestion={skipQuestion}
                                    isDocumentGenerated={isDocumentGenerated}
                                    edit={edit}
                                    setEdit={setEdit}
                                />
                                {/* {!(awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated) && !threadsData?.isSharedThread && ( */}
                                {/* {showActionButtons && (
                                    
                                )} */}
                            </div>
                        )}
                        {skipped.indexOf(index) !== -1 && (
                            <div className="flex flex-col gap-[8px] bg-[#FAFAFA] p-[8px] ml-[30px] rounded-[8px]">
                                <p className='flex flex-row text-[#13866F] gap-[8px]'>
                                    <Sparkle size={14.5} className='my-auto text-[#686868]' weight='bold' />
                                    <span className='text-[14px] my-auto leading-[22.5px] font-[600] text-[#686868]'>AI will pick the best option based on your needs</span>
                                </p>
                            </div>
                        )}
                    </>
                </div>
            </div>
        </div>
    );
};

export default QnA;