import { Lightning } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { PLANS } from 'src/components/Constants';

const UpgradeToPremiumButton = ({ openUpgradeModal, daysLeft, accountType }) => {
    const [message, setMessage] = React.useState('');

    useEffect(() => {
        if (accountType === PLANS.BUSINESS_TRIAL) {
            setMessage(`${daysLeft} days of trial left.`);
        }
        else if (accountType === PLANS.SUBSCRIBER_BUSINESS) {
            setMessage(`Plan renews in ${daysLeft} days.`);
        }
        else if (accountType === PLANS.BUSINESS_TRIAL_ENDED) {
            setMessage('Your Trial Plans has ended')
        }
    }, [accountType]);

    return (
        <button className="bg-incomplete border-[1px] w-[158x] h-[48px] rounded-[8px] gap-[8px] px-[12px] flex flex-row"
            onClick={() => { openUpgradeModal() }}>
            {daysLeft ?
                <span className="text-[#353535] my-auto text-[14px] leading-[19.6px]">{message}</span> : null
            }
            <span className="my-auto text-[14px] leading-[19.6px] text-underline">Upgrade to Unlimited Plan </span>
            <span className="my-auto"><Lightning weight="fill" size={16} className="text-[#DCA218] my-auto" /></span>
        </button>
    );
};

export default UpgradeToPremiumButton;