import { ArrowLeft, X } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { entityOptions, stateOptions } from '../../../../components/Constants';
import { useSelector } from 'react-redux';
import { Spinner } from "@phosphor-icons/react";

const AddPartyScreenComponent = (props) => {
    const { backToSelectPartyScreen, newParty, updateParty, updatePartyType, closeModal, addOrUpdateParty, editPartyId } = props;

    const [isDisabled, setIsDisabled] = useState(true)
    const [btnTitle, setBtnTitle] = useState("")
    const createNewPartyStatus = useSelector(state => state.homeslice.createNewPartyStatus);
    const [errors, setErrors] = useState({})

    const submit = () => {
        var formErrors = {}
        if (!newParty.full_name) {
            formErrors.full_name = "Full Name is required"
        }
        if (newParty.is_entity) {
            if (!newParty.state_incorporation) {
                formErrors.state_incorporation = "State of Incorporation is required"
            }
        }
        if (Object.keys(formErrors).length === 0) {
            addOrUpdateParty()
            setErrors({})
        }
        else {
            setErrors(formErrors)
        }
    }

    useEffect(() => {
        if (editPartyId === null) {
            setBtnTitle("Confirm And add")
        }
        else {
            setBtnTitle("Save")
        }
    }, [])

    useEffect(() => {
        if (newParty.is_entity) {
            if (!newParty.full_name || !newParty.state_incorporation || !newParty.primary_business_state || !newParty.entity_type || !newParty.address) {
                setIsDisabled(true)
            }
            setIsDisabled(false)
        }
        else {
            if (!newParty.full_name || !newParty.primary_business_state || !newParty.address) {
                setIsDisabled(true)
            }
            setIsDisabled(false)
        }
    }, [newParty])

    return (
        // <></>
        <>
            <div className="flex w-[860px] justify-between flex-row border-b gap-[12px] py-[20px] px-[41px]">
                <div className='flex flex-row gap-[12px]'>
                    <button
                        className=""
                        onClick={() => {
                            backToSelectPartyScreen()
                        }}
                    >
                        <ArrowLeft size={24} className=" my-auto" weight="bold" />
                    </button>
                    <div className='flex flex-col gap-[4px]'>
                        <p className="text-[24px] font-[600] leading-[33.6px] font-[Lora] italic">{!newParty.id ? "Add New Party" : "Edit Party"} </p>
                        {/* <p className='text-[#686868] text-[14px] leading-[19.6px] font-[400]'>Party details will be used by our AI while drafting the details</p> */}
                    </div>
                </div>
                <X
                    size={30}
                    className="hover:cursor-pointer my-auto"
                    color="black"
                    onClick={() => closeModal()}
                    id="cancel-draft-document-button"
                />
            </div>
            <div className="flex flex-col w-[860px] mx-auto justify-between px-[56px] gap-[4px]">
                <form className="flex flex-col gap-[16px]">

                    <div className="flex flex-col w-full gap-[8px] mt-[12px]">
                        <p className='text-[16px] font-[600]'>Party Type</p>
                        <div className="flex flex-row w-full gap-[36px]">
                            <select
                                id="has-entity"
                                className="bg-white border px-[12px] h-[40px] w-full"
                                name=""
                                value={newParty.is_entity}
                                onChange={(e) => {
                                    updatePartyType(e.target.value)
                                }}
                            >
                                <option value="" disabled>
                                    Select Party Type
                                </option>
                                <option value={true}>
                                    Company
                                </option>
                                <option value={false}>
                                    Individual
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-[8px]">
                        <label className='text-[18px] font-[600]'>{newParty.is_entity ? "Full Legal Name" : "Legal Name"}</label>
                        <input
                            type="text"
                            className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                            placeholder={newParty.is_entity ? "Full Legal Name" : "Legal Name"}
                            value={newParty.full_name}
                            onChange={(e) => updateParty("full_name", e.target.value)}
                        />
                        {errors?.full_name && !newParty.full_name && <p className="text-red-500 text-[14px]">{errors?.full_name}</p>}
                    </div>
                    <div className="flex flex-col w-full gap-[8px]">
                        <div className="gap-[4px]">
                            <label className='text-[18px] font-[600]'>State of {newParty.is_entity ? "Incorporation" : "Residence"} (optional)</label>
                            {/* <p className="text-[14px] leading-[21px] text-[#686868] font-[400]">Helps the AI apply the right jurisdiction to your document.</p> */}
                        </div>
                        <select
                            id="business-state"
                            className="bg-white border px-[12px] h-[40px]"
                            name="state"
                            value={newParty.state_incorporation}
                            onChange={(e) => updateParty("state_incorporation", e.target.value)}
                        >
                            <option value="" disabled>
                                Select State
                            </option>
                            {stateOptions.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>

                    {newParty.is_entity && <div className="flex flex-col w-full gap-[8px]">
                        <div className="gap-[4px]">
                            <label className='text-[18px] font-[600]'>Principle Place of Business (optional)</label>
                        </div>
                        <select
                            id="business-state"
                            className="bg-white border px-[12px] h-[40px]"
                            name="state"
                            value={newParty.primary_business_state}
                            onChange={(e) => updateParty("primary_business_state", e.target.value)}
                        >
                            <option value="" disabled>
                                Select Primary Business State
                            </option>
                            {stateOptions.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                        {/* <input
                            type="text"
                            className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                            placeholder="Principle Place of Business"
                            value={newParty.primary_business_state}
                            onChange={(e) => updateParty("primary_business_state", e.target.value)}
                        /> */}
                    </div>}

                    <div className="flex flex-col w-full gap-[8px]">
                        <div className="gap-[4px]">
                            <label className='text-[18px] font-[600]'>Address (optional)</label>
                            {/* <p className="text-[14px] leading-[21px] text-[#686868] font-[400]">Used by the AI to include accurate party details in your document.</p> */}
                        </div>
                        <input
                            type="text"
                            className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                            placeholder="Address"
                            value={newParty.address}
                            onChange={(e) => updateParty("address", e.target.value)}
                        />
                    </div>
                    {createNewPartyStatus === "failed" && <p className="text-red-500 text-[14px]">Failed to {editPartyId ? "update party information" : "add new party"}. Please try again. </p>}
                    <button
                        className="bg-[#18475A] text-white py-2 px-4 rounded-lg self-end"
                        disabled={isDisabled}
                        onClick={() => { submit() }}
                        type="button"
                    >
                        {createNewPartyStatus === "loading" ? <Spinner size={16} className="animate-spin" /> : btnTitle}
                    </button>
                </form >
            </div >

        </>
    );
};

export default AddPartyScreenComponent;