import { useDispatch, useSelector } from 'react-redux'
import { setShowPersonalPlanThreadsExhausted, setShowScheduleMeeting } from 'src/pages/onboarding/onboardingSlice'

import { X } from '@phosphor-icons/react/dist/ssr';
import { Modal } from 'antd';


const PersonalAccountThreadsExhausted = () => {
    const dispatch = useDispatch()

    const showPersonalPlanThreadsExhausted = useSelector((state) => state.onboarding.showPersonalPlanThreadsExhausted);

    const scheduleCall = () => {
        dispatch(setShowScheduleMeeting({ "show": true, "lawyer_details": {} }))
        dispatch(setShowPersonalPlanThreadsExhausted(false))
    }

    return (
        <Modal
            open={showPersonalPlanThreadsExhausted}
            className={`py-0 overflow-x-hidden`}
            footer={null}
        >            <div className="flex flex-end justify-end w-full p-3">
                <X size={24} className="cursor-pointer" onClick={() => dispatch(setShowPersonalPlanThreadsExhausted(false))} />
            </div>
            <div className='flex flex-col items-center pt-4 pb-8'>
                <p className='text-2xl font-bold text-[#011513]'>You have 0 new tasks left </p>
                <p className='text-sm font-normal text-[#686868] mt-1 text-center'>
                    We would like to understand your use case as a Personal Account. <br />
                    Please schedule a call with us to discuss your requirements.
                </p>

                <div className="flex flex-col items-center mt-8">
                    <button className="flex items-center justify-center bg-[#DCA218] text-white font-bold text-sm rounded-[8px] px-4 py-2" onClick={scheduleCall}>Schedule Call</button>
                </div>

            </div >
        </Modal>
    )
}

export default PersonalAccountThreadsExhausted