import { ArrowLeft, ArrowRight, Lightning } from '@phosphor-icons/react';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const FinalTouchesScreen = (props) => {
    const { backtoScreen1, createDraft, finalNotes, setFinalNotes } = props;

    const [alertMessage, setAlertMessage] = useState("");

    const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);

    const placeholderText = `E.g.: 'I need a contract to hire freelancer with payment terms and deliverables.'`

    const submit = () => {
        if (finalNotes?.length < 10) {
            setAlertMessage("Please describe your draft request in atleast 10 characters")
        }
        else {
            createDraft()
        }
    }

    return (

        <>
            <div className="flex flex-col gap-[18px] py-[18px] w-full  mx-auto border-t">
                <div className="flex flex-col w-full h-[277px] mx-auto gap-[20px] px-[18px] md:px-[44px]">
                    <div className="flex flex-col gap-[6px]">
                        <h3><span className="text-[18px] leading-[23.44px] font-semibold text-[#353535]">Describe the situation</span>
                        </h3>
                        <p>Share the background and any key details</p>
                    </div>
                    <div className='flex flex-col'>
                        <TextArea autoSize={{ minRows: 8, maxRows: 15 }}
                            value={finalNotes}
                            onChange={(e) => { setAlertMessage(""); setFinalNotes(e.target.value) }}
                            className="border z-2 focus-within:!border-black rounded-[8px] p-[16px]  !text-[14px]" placeholder={placeholderText} />
                        {/* <p className='flex gap-2 items-center font-sans font-normal  rounded-b-[12px] text-[14px] text-[#141414] bg-[#ECF5F3] p-3 relative bottom-1 '> <Lightning size={18} weight='fill' className='text-[#248370]' />The AI will tailor your document based on your inputs</p> */}
                    </div>
                    {alertMessage &&
                        <p className='text-red-500'>{alertMessage}</p>
                    }
                </div>
            </div>
            <div className="flex flex-row justify-between border-[#E4E4E4] border !border-b-0 mt-[70px] mb-[-25px] h-[96px] rounded-[8px] px-[20px] md:px-[40px] py-[24px]">
                <button className="flex flex-row gap-[10px] items-center justify-center h-[30px] text-[#353535] font-[600] text-[14px] border-r-[#E4E4E4] my-auto py-[12px] px-[24px]"
                    onClick={() => { backtoScreen1() }}>
                    <ArrowLeft size={18} className="text-[#302F2F]" />
                    <span>Go Back</span>
                </button>
                <div className="flex flex-row justify-between">


                    <button className="bg-[#18475A] font-sanstext-[16px] font-medium border-non self-end flex justify-center items-center gap-[10px] text-white px-[24px] py-[12px] rounded-xl"
                        onClick={submit}
                        disabled={awaitingResponse}
                    >
                        {awaitingResponse ? <div className="w-6 h-6 border-2 border-t-0 border-[#fff] rounded-full animate-spin"></div> :
                            <>
                                <span>Continue</span>
                                <ArrowRight size={16} className="text-[#fff]" />
                            </>
                        }
                    </button>
                </div>
            </div>
        </>
    );
};

export default FinalTouchesScreen;