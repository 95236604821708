import { Briefcase, Check } from '@phosphor-icons/react';
import React from 'react';

const ReviewCompletedButton = () => {

    return (
        <button className="bg-accurate border-[1px] border-[#137513] w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row">
            <span className="my-auto"><Briefcase size={14} className="text-white my-auto" /></span>
            <span className="text-white my-auto text-[14px] leading-[19.6px]"> Review Completed</span>
            <span className="my-auto"><Check size={14} className="text-[#137513] my-auto" /></span>
        </button>);
};

export default ReviewCompletedButton;