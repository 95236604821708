import React from 'react'
import TextInput from '../Inputs/TextInput'
import SelectInput from '../Inputs/Select'
import { stateOptionsNew } from "../../../components/Constants";

const IndividualForm = (props) => {
  const {
    organizationName, setOrganizationName,
    incorporationState, setIncorporationState,
    businessPurpose, setPurposeChange,
    errors
  } = props
  return (
    <div className='mb-4'>
      <TextInput
        wrapperClassName='mt-3 font-sans'
        label={<p className='text-xl font-medium'>Your legal name</p>}
        placeholder='Eg,. John Doe'
        value={organizationName}
        onChange={setOrganizationName}
        error={errors?.organizationName && (!organizationName || organizationName?.length < 3) && errors.organizationName}
      />
      <SelectInput
        wrapperClassName='mt-3 font-sans'
        label={<p className='text-xl font-medium font-sans'>State of Residence<sup></sup></p>}
        placeholder='Select the state where you reside '
        value={incorporationState || "California"}
        onChange={setIncorporationState}
        options={stateOptionsNew}
        info={false}
        error={errors?.incorporationState && !incorporationState && errors.incorporationState}
      />
      {/* <TextInput
        wrapperClassName='mt-3'
        label={<p>What brings you to InHouse<sup></sup>?</p>}
        placeholder='Eg,. I am in the process of building a startup. I am here to get legal advice for the same. '
        value={businessPurpose}
        onChange={setPurposeChange}
      /> */}
    </div>
  )
}

export default IndividualForm