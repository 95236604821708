import React from "react";
import TextInput from "../onboardingV2/Inputs/TextInput";
import SelectInput from "../onboardingV2/Inputs/Select";
import { stateOptionsNew, entityOptions } from "src/components/Constants";

const OnboardingStep2 = ({
  businessPurpose,
  organizationName,
  setCompanyChange,
  setPurposeChange,
  businessState,
  incorporationState,
  setPrimaryStateChange,
  setIncorporationStateChange,

  errors }) => {
  return (
    <div className="flex flex-col gap-[16px] w-full py-4">
      <TextInput
        wrapperClassName=""
        label={
          <p className="flex flex-col gap-[4px]">
            <span className="text-xl">What is your company called?</span>
            <span className="text-sm text-[#686868] font-[500]">The AI will use this to fill in documents and contextualize your questions</span>
          </p>
        }
        value={organizationName}
        placeholder="Company Name"
        onChange={setCompanyChange}
        error={(errors && errors["organizationName"] && (!organizationName || organizationName?.length < 3)) && errors["organizationName"]}
      />
      <TextInput
        wrapperClassName=""
        label={
          <p className="text-xl">
            Purpose of Business<sup></sup>
          </p>
        }
        value={businessPurpose}
        placeholder={`Eg: We sell anvils to cartoon characters. It's an online business\nwith 15 employees.`}
        onChange={setPurposeChange}
        rows={6}
        error={(errors && errors["businessPurpose"] && (!businessPurpose || businessPurpose?.length < 3)) && errors["businessPurpose"]}
      />
      <SelectInput
        wrapperClassName=""
        label={
          <p className="text-xl">
            Primary State of Business<sup></sup>
          </p>
        }
        placeholder="Select the state your company conducts its business operations"
        onChange={setPrimaryStateChange}
        options={stateOptionsNew}
        value={businessState || "California"}
        error={errors?.businessState && !businessState && errors.businessState}
      />
      <SelectInput
        wrapperClassName=""
        label={
          <p className="text-xl">
            State of Incorporation<sup></sup>
          </p>
        }
        placeholder="Select the state where the company was legally formed and registered"
        onChange={setIncorporationStateChange}
        options={stateOptionsNew}
        value={incorporationState || "California"}
        error={errors?.incorporationState && !incorporationState && errors.incorporationState}
      />
    </div>
  );
};

export default OnboardingStep2;
