import React from 'react';
import { createPortal } from 'react-dom';
import './modal.css';
import useToggleAnimatedDialog from '../../hooks/useToggle';
import {
  X,
} from "@phosphor-icons/react";

const Modal = (props) => {

  const {
    title,
    children,
    body,
    bodyStyle,
    bodyClassName,
    onClose,
    isOpen,
    contentAreaStyles,
    closeBtnClassName
  } = props;

  const [showPopup] = useToggleAnimatedDialog(isOpen, 300);

  // return null if portal element does not exist in DOM,
  // or if showPopup is false
  if (!document.getElementById('modal-portal') || !showPopup) return null;

  // use  createPortal() to render element at different location in DOM
  // even though it is written at different location in code.

  const backgroundAnimation = isOpen ? 'fade-in-animation' : 'fade-out-animation';
  const contentAreaAnimation = isOpen ? 'zoom-in-animation' : 'zoom-out-animation';

  return (createPortal(
    <div
      style={{
        animationName: backgroundAnimation
      }}
      className='popup-background'
    >
      <div style={{
        animationName: contentAreaAnimation,
        ...contentAreaStyles
      }}
        className='container relative content-area'
      >
        {
          // if title is passed then only display this element
          title &&
          <div className='flex items-center justify-between'>
            {title}
          </div>
        }
        {onClose && <div className={`absolute right-10 top-8 ${closeBtnClassName ? closeBtnClassName : ""} p-2 cursor-pointer`} onClick={onClose}>
          <X
            size={24}
            className="ml-auto"
            color="#949494"
            id="cancel-legal-review-button"

          />
        </div>}
        <div className={`h-full py-12 overflow-scroll ${bodyClassName}`} style={{ ...bodyStyle }}>{children || body}</div>
      </div>
    </div>,
    document.getElementById('modal-portal')
  ));
}

export default Modal;